import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import {
    resetForgetPassword, forgetPasswordRequest
} from "../../../store/actions"
// import NidaFormart from '../../../utils/formartNida'

import { CssVarsProvider } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import theme from '../../../utils/theme';
import { Avatar, Card, Link } from '@mui/joy';

import { useNavigate } from 'react-router-dom';

import image from '../../../constant/image';
import { NAVIGATE_TO_LOGINPAGE } from '../../../route/types';
import { toast } from 'react-toastify';
import { STATUS } from '../../../constant';
import { LoadingView } from '../../../components';
import { NidaFormat } from '../../../utils';
import { useTranslation } from 'react-i18next';

interface FormElements extends HTMLFormControlsCollection {
    nida: HTMLInputElement;
    // usermail: HTMLInputElement;
    mobile: HTMLInputElement;
    // persistent: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

const ForgetPasswordPage = ({
    status,
    errorMessage
}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [nida, setNida] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        if (status === STATUS.SUCCESS) {
            // reset the guest app state
            toast.success(t("forget.success"))
            setTimeout(() => {
                navigate(NAVIGATE_TO_LOGINPAGE)
            }, 1000)
            dispatch(resetForgetPassword());
        }
        else if (status == STATUS.ERROR) {
            // reset the guest app state
            toast.error(errorMessage)
            dispatch(resetForgetPassword());
        }
        //console.log(status);
    })


    const handleSubmit = (event) => {
        event.preventDefault();
        const formElements = event.currentTarget.elements;
        const data = {
            nida: nida,
            mobile: formElements.mobile.value,
        };
        if (data.nida != "" && data.mobile != "") {
            dispatch(forgetPasswordRequest(data.nida, data.mobile))

        } else {
            toast.error(t("forget.error"));
        }
    }

    const checkLoading = () => {
        if (status === STATUS.LOADING) {
            return true
        } else {
            return false
        }
    }
    return (
        <CssVarsProvider defaultMode="light" disableTransitionOnChange theme={theme}>
            <CssBaseline />
            <GlobalStyles
                styles={{
                    ':root': {
                        '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
                        '--Cover-width': '50vw', // must be `vw` only
                        '--Form-maxWidth': '600px',
                        '--Transition-duration': '0.4s', // set to `none` to disable transition
                    },
                }}
            />

            {/* loading  */}
            <LoadingView loading={checkLoading()} />

            <Box
                sx={(theme) => ({
                    //   width: '90%',
                    transition: 'width var(--Transition-duration)',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    position: 'relative',
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'center',

                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage:
                        `url(${image.Images.backgroung})`,
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundImage:
                            `url(${image.Images.backgroung2})`,
                    },
                })}
            >

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100dvh',
                        maxWidth: '100%',
                        px: 2,
                    }}
                >
                    <Box
                        component="header"
                        sx={{
                            py: 4,
                            gap: 1,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar
                            src={image.Images.logoWhite}
                            size='sm'
                            sx={{
                                maxWidth: 80, maxHeight: 80, backgroundColor: 'primary.500', padding: '5px',
                            }}
                        />
                        <Typography level='title-lg'>SAVA</Typography>
                    </Box>
                    <Card
                        component="main"
                        sx={{
                            my: 'auto',
                            py: 2,
                            pb: 5,
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: 400,
                            maxWidth: '100%',
                            mx: 'auto',

                            backgroundColor: 'background.body',
                            borderRadius: 'sm',
                            '& form': {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            },
                            [`& .${formLabelClasses.asterisk}`]: {
                                visibility: 'hidden',
                            },
                        }}
                    >
                        <Stack gap={4} sx={{ mb: 1 }}>
                            <Stack gap={1}>
                                <Typography level="h3">{t("forget.title")}</Typography>
                                <Typography level="body-sm">
                                    {t("forget.switch") + ' '}
                                    <Link href={NAVIGATE_TO_LOGINPAGE} sx={{ color: 'green' }} level="title-sm">
                                        {t("forget.signin")}!
                                    </Link>
                                </Typography>
                            </Stack>
                        </Stack>

                        {/* ambassadorclient switch */}
                        <Typography level="body-sm" sx={{ textAlign: 'center' }}>
                            {t("forget.desc")}
                        </Typography>

                        <Stack component='form' onSubmit={handleSubmit} gap={4} sx={{ mt: 1 }} noValidate>
                            <FormControl required>
                                <FormLabel>{t("forget.nida")}</FormLabel>
                                <Input
                                    // type="number" 
                                    name="nida"
                                    value={nida}
                                    onChange={(event) => setNida(event.target.value)}
                                    placeholder={t("forget.nidaPlaceholder")}
                                    slotProps={{
                                        input: { component: NidaFormat }
                                    }} />
                            </FormControl>
                            <FormControl required>
                                <FormLabel>{t("forget.mobile")}</FormLabel>
                                <Input type="tel" name="mobile" placeholder={t("forget.mobilePlaceholder")} />
                            </FormControl>
                            <Stack gap={4} sx={{ mt: 2 }}>
                                <Button type="submit" fullWidth>
                                    {t("forget.button")}
                                </Button>
                            </Stack>
                        </Stack>
                    </Card>
                    <Box component="footer" sx={{ py: 3 }}>
                        <Typography level="body-xs" textAlign="center" sx={{ color: 'whitesmoke' }}>
                            {t("intro.owner")}
                        </Typography>
                        <Typography level="body-xs" textAlign="center" sx={{ color: 'whitesmoke' }}>
                            {t("intro.assistance1")}
                            <a href="tel:+255672600151" style={{ color: 'orange' }}>+255 672 600 151</a>
                            {t("intro.assistance2")}
                            <a href="mailto:support@sava.co.tz" style={{ color: 'orange' }}> support@sava.co.tz</a>
                        </Typography>
                    </Box>
                </Box>
            </Box>

        </CssVarsProvider>
    );
}


const mapStateToProps = ({ guest }) => {
    const {
        forgetPasswordStatus: status,
        forgetPasswordErrorMessage: errorMessage
    } = guest;

    return {
        status,
        errorMessage
    }
}

export default connect(mapStateToProps, {})(ForgetPasswordPage)