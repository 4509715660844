import { CLIENT_CHAT_HISTORY_URL, CLIENT_DETAILS_URL, CLIENT_NOTIFICATION_URL, UPLOAD_SIGNATURE_URL, PAYMENT_METHOD_URL, PUSH_PAYMENT_URL, SEND_CLIENT_CHAT_URL, SUPPORT_CONTENT_URL, TRANSACTION_HISTORIES_URL, UPLOAD_PROFILE_PHOTO_URL, AMBASSADOR_APPLICATION_URL } from "../../constant";
import { multipartRequest, resourceRequest } from "../calls";


function doFetchSupportContent(token, language, category) {
    const data = {
        language: language,
        category: category
    };

    return resourceRequest(token, SUPPORT_CONTENT_URL, data);
}

function doFetchPaymentMethod(token, language) {
    const data = {
        language: language
    };
    return resourceRequest(token, PAYMENT_METHOD_URL, data);
}

function doUploadProfilePhoto(token, file) {
    const formData = new FormData();

    formData.append('picture', file)

    return multipartRequest(token, UPLOAD_PROFILE_PHOTO_URL, formData)
}

function doUploadSignature(token, file) {
    const formData = new FormData();

    formData.append('signature', file)

    return multipartRequest(token, UPLOAD_SIGNATURE_URL, formData)
}

function doTransactionHistoryRequest(token) {
    const data = {};

    return resourceRequest(token, TRANSACTION_HISTORIES_URL, data);
}

function doSendClientChat(token, message) {
    const data = {
        "message": message
    }
    return resourceRequest(token, SEND_CLIENT_CHAT_URL, data);
}

function doFetchClientChatHistory(token) {
    const data = {}
    return resourceRequest(token, CLIENT_CHAT_HISTORY_URL, data);
}

function doFetchClientNotification(token) {
    const data = {}
    return resourceRequest(token, CLIENT_NOTIFICATION_URL, data)
}

function doFetchClientDetails(token) {
    const data = {}
    return resourceRequest(token, CLIENT_DETAILS_URL, data)
}

function doApplyAmbassador(token, name, mobile, email, region, district, bussiness, ambType) {
    const data = {
        "name": name,
        "email": email,
        "mobile": mobile,
        "region": region,
        "district": district,
        "source_of_income": bussiness,
        "ambassador_type": ambType
    }
    return resourceRequest(token, AMBASSADOR_APPLICATION_URL, data)
}

export {
    doFetchSupportContent,
    doUploadProfilePhoto,
    doUploadSignature,
    doFetchPaymentMethod,
    doTransactionHistoryRequest,
    doSendClientChat,
    doFetchClientChatHistory,
    doFetchClientNotification,
    doFetchClientDetails,
    doApplyAmbassador
}