// ---- LOCAL URL FOR TEST ----- //
// export const API_BASE = 'http://192.168.100.74:8081'; // CHIDIEL
// export const FILE_BASE = 'http://192.168.100.74:8083';
export const OTHER_BASE = 'https://sava.co.tz';

// ------ LIVE URL------ //
export const FILE_BASE = 'https://sava-api.tbridgetech.com';
export const API_BASE = 'https://sava-api.tbridge.africa'

// ------ ENDPOINS -------
// AUTHENTICATIONS
export const LOGIN_CLIENT_URL = '/auth/login';
export const CLIENT_OTP_URL = '/auth/verify';
export const REGISTRATION_URL = '/auth/create';
export const OTP_URL = '/auth/mobile-verify';
export const UPDATE_OTP_URL = '/auth/resend-code';
export const FORGET_PASSWORD_URL = '/auth/forget-password';

// LOAN
export const AMBASSADOR_VALIDATE_URL =  '/resource/confirm-ambassador';
export const UNDERWRINTING_URL = '/resource/loan-request-with-underwriting';
export const LOAN_APPROVAL_URL = '/resource/loan-request';
export const EDIT_UNDERWRINTING_URL = '/resource/update-loan-request';
export const EDIT_LOAN_APPROVAL_URL = '/resource/update-loan-request';
export const LOAN_HISTORY_URL = '/resource/loan-history';
export const PENDING_LOAN_DETAIL_URL = '/resource/pending-loan-details';
export const ACTIVE_LOAN_DETAIL_URL = '/resource/active-loan-details';
export const PUSH_PAYMENT_URL = '/resource/selcom-loan-payment';

// NIDA VERIFICATION
export const NIDA_VERIFICATION_URL = '/resource/send-reference-request';
export const REFERENCE_URL = '/resource/check-ref-status';

// HISTORY AND TRANSACTIONS
export const TRANSACTION_HISTORIES_URL = '/resource/transaction-history';
export const CLIENT_CHAT_HISTORY_URL = '/resource/chat-history';
export const CLIENT_NOTIFICATION_URL = '/resource/notification';

// USER DETAILS & CUSTOMIZATIONS
export const CLIENT_DETAILS_URL = '/resource/my-details';
export const CHANGE_USER_PIN_URL = '/resource/change-password';
export const UPLOAD_PROFILE_PHOTO_URL = '/resource/change-profile';
export const UPLOAD_SIGNATURE_URL = '/resource/save-signature';

// HELP & SUPPORT
export const SUPPORT_CONTENT_URL = '/resource/customer-support';
export const PAYMENT_METHOD_URL = '/resource/customer-support';
export const TERMS_AND_CONDITION_URL = '/auth/terms-and-policies';
export const SEND_CLIENT_CHAT_URL = '/resource/chat-message';
export const AMBASSADOR_APPLICATION_URL = '/resource/ambassador-application';

