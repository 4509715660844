import { Box, GlobalStyles, Link, Stack, Typography } from "@mui/joy"
import React from "react"
import { useLocation } from "react-router-dom";

import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import WalletRoundedIcom from '@mui/icons-material/WalletRounded';
import CurrentExchangeRoundIcon from '@mui/icons-material/CurrencyExchangeOutlined'
import { NAVIGATE_TO_CLIENTLOANHISTORYPAGE, NAVIGATE_TO_CLIENTTRANSACTIONHISTORYPAGE, NAVIGATE_TO_HOMECLIENTPAGE } from "../../route/types";
import { useTranslation } from "react-i18next";

export default function BottomNavigation() {
    const {t} = useTranslation();
    const location = useLocation();

    const BottomItems = ({ title, route, icon }) => {
        return (
            <Box sx={{ margin: 'auto', flex: 1, }}>
                {/* <Typography level='title-sm'>{title}</Typography> */}
                <Link
                    href={route}
                    underline='none'
                    sx={{
                        color: location.pathname == route ? 'background.appcolor' : 'gray',
                        fontWeight: location.pathname == route ? '600' : 'normal',
                        display: 'flex',
                        flexDirection: 'column',
                        fontSize: '18px'
                    }}>
                    {icon}
                    <Typography level='title-sm' fontSize='xs' sx={{color: location.pathname == route ? 'background.appcolor' : 'gray',}}>{title}</Typography>
                </Link>
                <Box sx={{
                    display: location.pathname == route ? 'flex' : 'none',
                    width: '15px',
                    borderRadius: '50px',
                    height: '5px',
                    backgroundColor: 'background.appcolor',
                    margin: 'auto'
                }} />
            </Box>
        )
    }
    return (
        <Stack
            id="tab-bar"
            direction="row"
            justifyContent="space-around"
            spacing={1}
            sx={{
                display: { xs: 'flex', sm: 'none' },
                zIndex: '999',
                bottom: 0,
                position: 'fixed',
                width: '100dvw',
                height: 'var(--Bottom-tab-height)',
                py: 2,
                backgroundColor: 'background.body',
                borderTop: '1px solid',
                borderColor: 'divider',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                    ':root': {
                        '--Bottom-tab-height': '60px',
                        [theme.breakpoints.up('md')]: {
                            '--Bottom-tab-height': '0px',
                        },
                    },
                })}
            />
            <BottomItems title={t("header.home")} route={NAVIGATE_TO_HOMECLIENTPAGE} icon={<HomeRoundedIcon />} />
            <BottomItems title={t("header.loan")} route={NAVIGATE_TO_CLIENTLOANHISTORYPAGE} icon={<WalletRoundedIcom />} />
            <BottomItems title={t("header.transaction")} route={NAVIGATE_TO_CLIENTTRANSACTIONHISTORYPAGE} icon={<CurrentExchangeRoundIcon />} />
        </Stack>
    )
}

