import { CLIENT } from "../../constant"

//action to trigger auth state change
export function guestLoginRequest(username, password, fcm_token) {
    return {
        type: CLIENT.GUEST_LOGIN_REQUEST,
        payload: {
            username,
            password,
            fcm_token
        }
    }
}

export function resetGuestLoginRequest() {
    return {
        type: CLIENT.GUEST_LOGIN_RESET
    }
}

export function termsAndConditionRequest(category,language) {
    return {
        type: CLIENT.TERMS_AND_CONDITION_REQUEST,
        payload: {
            category,
            language
        }
    }
}

export function resetTermsAndConditionRequest() {
    return {
        type: CLIENT.TERMS_AND_CONDITION_RESET
    }
}

export function forgetPasswordRequest(nida,mobile) {
    return {
        type: CLIENT.FORGET_PASSWORD_REQUEST,
        payload: {
            nida,
            mobile
        }
    }
}

export function resetForgetPassword() {
    return {
        type: CLIENT.FORGET_PASSWORD_RESET
    }
}

