export const encodedData = (out_data) => {
    const utf8Str = unescape(encodeURIComponent
        (JSON.stringify(out_data)));
    const encoded = btoa(utf8Str);
        
    return encoded
}

export const decodeData = (income_data) => {
    var decoded = JSON.parse(atob(income_data))
    console.log('decoded data: ', decoded)
    return decoded
}
