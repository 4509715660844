import React, { useEffect, useState } from "react";
import { AspectRatio, Avatar, Box, Button, Card, CardContent, CircularProgress, DialogContent, DialogTitle, Divider, FormControl, FormLabel, IconButton, Input, Modal, ModalClose, ModalDialog, Stack, Typography } from "@mui/joy";
import { toast } from 'react-toastify';
import { ClientMain, LoadingView, PageTitle } from "../../../components";
import { connect, useDispatch } from "react-redux";
import { AssignmentOutlined, DescriptionOutlined, EditNoteRounded, EditRounded, HelpOutline, HelpRounded, InfoOutlined, InfoRounded, LockOutlined, PeopleAlt, PolicyOutlined, PolicyRounded, PrivacyTipOutlined, PrivacyTipRounded, SecurityRounded } from "@mui/icons-material";
import { FILE_BASE, STATUS } from "../../../constant";

import {
    clientDetailsRequest,
    clientDetailsReset,
    changeUserPinRequest,
    changeUserPinReset,
    fetchSupportContent,
    fetchSupportContentReset,
    resetTermsAndConditionRequest,
    termsAndConditionRequest,
    uploadProfilePhoto,
    uploadProfilePhotoReset
} from "../../../store/actions"
import { useNavigate } from "react-router-dom";
import { NAVIGATE_TO_AMBASSADORAPPLICATIONPAGE, NAVIGATE_TO_CLIENTSUPPORTPAGE, NAVIGATE_TO_LOANDESCRIPTIONPAGE, NAVIGATE_TO_TERMSANDCONDITIONPAGE } from "../../../route/types";
import { CardHeader } from "@mui/material";
import { TermsAndConditions } from "../../Support";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n/i18n.js";

interface FormElements extends HTMLFormControlsCollection {
    oldPassword: HTMLInputElement;
    newPassrword: HTMLInputElement;
    confirmPassword: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

const ClientProfilePage = ({
    loginResult,

    supportDataResult,
    supportDataStatus,
    supportDataErrorMessage,
    termsResult,
    termsStatus,
    termsErrorMessage,

    changeUserPinStatus,
    changeUserPinResult,
    changeUserPinErrorMessage,

    clientDetailsStatus,
    clientDetailsResult,
    clientDetailsErrorMessage,

    profileStatus,
    profileResult
}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [name, setName] = useState('');
    const [profile, setProfile] = useState('');
    const [phone, setPhone] = useState('');
    const [nida, setNida] = useState('')
    const [nidaStatus, setNidaStatus] = useState(0);
    const [profileFile, setProfileFile] = useState("");

    const [loading, setLoading] = useState(false)
    const [passModal, setPassModal] = useState(false)

    useEffect(() => {
        if (clientDetailsStatus === STATUS.SUCCESS) {
            // console.log("CLIENT DATA: ", clientDetailsResult)
            setName(clientDetailsResult.message.name);
            setPhone(clientDetailsResult.message.mobile_number);
            setNida(clientDetailsResult.message.nida);
            setNidaStatus(clientDetailsResult.nida_status);
            setProfile(FILE_BASE + "/" + clientDetailsResult.message.profile_picture);
        } else if (clientDetailsStatus === STATUS.ERROR) {
            setName(loginResult.name)
            setPhone(loginResult.mobile)
            setNida(loginResult.nida)
            setProfile(FILE_BASE + "/" + loginResult.profile_photo_url)
            dispatch(clientDetailsReset())
        }

        if (changeUserPinStatus === STATUS.SUCCESS) {
            toast.success(t("changePin.success"))
            setLoading(false);
            setPassModal(false);
            dispatch(changeUserPinReset())

        } else if (changeUserPinStatus === STATUS.ERROR) {
            setLoading(false);
            dispatch(changeUserPinReset())
            toast.error(changeUserPinErrorMessage)
        }

        if (termsStatus === STATUS.SUCCESS) {
            navigate(NAVIGATE_TO_TERMSANDCONDITIONPAGE, {
                state: {
                    content: termsResult.content
                }
            })
            dispatch(resetTermsAndConditionRequest());
        }
        else if (termsStatus === STATUS.ERROR) {
            toast.error(termsErrorMessage)
            dispatch(resetTermsAndConditionRequest());
        }

        if (supportDataStatus === STATUS.SUCCESS) {
            navigate(NAVIGATE_TO_LOANDESCRIPTIONPAGE, {
                state: {
                    content: supportDataResult.content
                }

            });
            dispatch(fetchSupportContentReset());
        }
        else if (supportDataStatus === STATUS.ERROR) {
            toast.error("Failed to fetch loan descriptions")
            dispatch(fetchSupportContentReset());
        }
    }, [clientDetailsStatus, changeUserPinStatus, termsStatus, supportDataStatus])

    const handleSubmit = (event) => {
        event.preventDefault();
        const formElements = event.currentTarget.elements;
        const data = {
            newPassword: formElements.newPassword.value,
            oldPassword: formElements.oldPassword.value,
            confirmPassword: formElements.confirmPassword.value,
        };
        console.log(data)
        if (data.newPassword && data.oldPassword && data.confirmPassword) {
            if (data.newPassword === data.confirmPassword) {
                setLoading(true)
                dispatch(changeUserPinRequest(loginResult.token, data.oldPassword, data.newPassword, data.confirmPassword));
            } else {
                toast.error(t("changePin.notMatch"))
            }
        } else {
            toast.error(t("changePin.emptyErr"))
        }
    }

    // profile functions
    const changeProfile = (event) => {
        setProfileFile(event.target.files[0])
        setProfile(URL.createObjectURL(event.target.files[0]))
    }

    const sendProfile = () => {
        if (profileFile) {
            dispatch(uploadProfilePhoto(loginResult.token, profileFile))
        }
        else {
            toast.error(t("profile.emptyPic"))
        }

    }

    useEffect(() => {
        if (profileStatus === STATUS.SUCCESS) {
            toast.success(t("profile.successUpload"));
            setProfileFile("");
            dispatch(uploadProfilePhotoReset());
        }
        else if (profileStatus === STATUS.ERROR) {
            toast.error(t("profile.failureUpload"))
            dispatch(uploadProfilePhotoReset());
        }
    }, [profileStatus])

    // ---- terms, policy and loan decription request
    const viewTerms = () => {
        let language = i18n.language;
        let category = "term"
        dispatch(termsAndConditionRequest(category, language))
    }

    const viewLoanDescription = () => {
        let category = "description";
        let language = i18n.language;
        dispatch(fetchSupportContent(loginResult.token, language, category));
    }

    // ----- User details array
    const userdetails = [
        { title: t("profile.fullname"), value: name.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') },
        { title: t("profile.phone"), value: "+" + phone },
        { title: t("profile.nida"), value: nida },
        { title: t("profile.nidaStatus"), value: nidaStatus === 2 ? t("profile.verified") : t("profile.notVerified") },
    ]

    // ---- Setting details and actions array
    const settingDetails = [
        { title: t("setting.changePin"), icon: <LockOutlined />, action: () => setPassModal(true) },
        { title: t("setting.ambassadorApplication"), icon: <DescriptionOutlined />, action: () => navigate(NAVIGATE_TO_AMBASSADORAPPLICATIONPAGE) },
        { title: t("setting.dataProtection"), icon: <PrivacyTipOutlined />, action: () => window.open(FILE_BASE + "/" + loginResult.privacy_policy) },
        { title: t("setting.terms"), icon: <InfoOutlined />, action: viewTerms },
        { title: t("setting.loanDescriptions"), icon: <AssignmentOutlined />, action: viewLoanDescription },
        { title: t("setting.help"), icon: <HelpOutline />, action: () => navigate(NAVIGATE_TO_CLIENTSUPPORTPAGE) }
    ]

    const loadingState = () => {
        if (termsStatus === STATUS.LOADING || supportDataStatus === STATUS.LOADING || profileStatus === STATUS.LOADING) {
            return true
        }
        else {
            return false
        }
    }

    return (
        <Box>
            <PageTitle title={t("setting.title")} />

            <LoadingView loading={loadingState()} />

            <Card
                sx={{
                    maxWidth: '800px',
                    alignSelf: 'center',
                    display: 'flex',
                    m: 'auto'
                }}>
                <Box>
                    <Typography level="title-md">{t("profile.title")}</Typography>
                    <Typography level="body-sm">{t("profile.desc")}</Typography>
                </Box>
                <Divider />
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: { xs: 1, md: 3 }
                    }}>
                    <Stack
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                        <Avatar
                            variant="outlined"
                            src={profile}
                            sx={{
                                width: 100,
                                height: 100,
                                borderRadius: 100,
                                borderWidth: 3
                            }} />
                        <IconButton
                            aria-label="upload new picture"
                            size="sm"
                            variant="outlined"
                            color="neutral"
                            sx={{
                                bgcolor: 'background.body',
                                width: 35,
                                height: 35,
                                alignSelf: 'flex-end',
                                borderRadius: '50%',
                                left: 0,
                                top: '-25%',
                            }}
                        >
                            <input type="file" id="profile-file" hidden
                                style={{ display: 'none' }}
                                onChange={changeProfile} />
                            <label htmlFor="profile-file" style={{ width: '100%', alignItems: 'center' }}><EditRounded /></label>
                        </IconButton>
                        {profileFile && <Button color="success" size="sm" variant="outlined" onClick={sendProfile} sx={{ top: '-16%' }}>Upload</Button>}
                    </Stack>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                        {userdetails.map((item, index) => (
                            <Typography key={index} level="body-sm"><b>{item.title}:</b> {item.value}</Typography>
                        ))}
                    </Box>
                </CardContent>
            </Card>

            <Card
                sx={{
                    maxWidth: '800px',
                    alignSelf: 'center',
                    display: 'flex',
                    m: "auto",
                    mt: { xs: 2, md: 3 },
                }}>
                <Box>
                    <Typography level="title-md">{t("setting.setting")}</Typography>
                    <Typography level="body-sm">{t("setting.desc")}</Typography>
                </Box>
                <Divider />
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        // gap: 1
                    }}>


                    {settingDetails.map((item, index) => (
                        <>
                            <Button
                                variant="plain"
                                color="neutral"
                                onClick={item.action}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    borderBottomWidth: 1,
                                    gap: 1
                                }}>
                                <Typography level="title-sm">{item.title}</Typography>
                                {item.icon}
                            </Button>
                            <Divider />
                        </>
                    ))}
                </CardContent>
            </Card>

            {/* password change modal */}
            <Modal open={passModal} >
                <ModalDialog
                    aria-labelledby="nested-modal-title"
                    aria-describedby="nested-modal-description"
                    sx={(theme) => ({
                        [theme.breakpoints.only('xs')]: {  // ----------------> FOR MOBILE
                            top: 'unset',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            // borderRadius: 0,
                            borderTopLeftRadius: 20,
                            borderTopRightRadius: 20,
                            transform: 'none',
                            maxWidth: 'unset',
                        },
                    })}>
                    <ModalClose variant="outlined" component="button" onClick={() => setPassModal(false)} />
                    <DialogTitle>{t("changePin.title")}</DialogTitle>
                    <DialogContent>{t("changePin.pinRule")}</DialogContent>
                    <form
                        onSubmit={handleSubmit}
                    >
                        <FormControl sx={{ pb: 1 }}>
                            <FormLabel>{t("changePin.old")}</FormLabel>
                            <Input
                                autoFocus
                                type="password"
                                name="oldPassword"
                                required
                            />
                        </FormControl>
                        <FormControl sx={{ mt: 1 }}>
                            <FormLabel>{t("changePin.new")}</FormLabel>
                            <Input
                                autoFocus
                                type="password"
                                name="newPassword"
                                required
                            />
                        </FormControl>
                        <FormControl sx={{ pb: 1 }}>
                            <FormLabel>{t("changePin.confirm")}</FormLabel>
                            <Input
                                autoFocus
                                type="password"
                                name="confirmPassword"
                                required
                            />
                        </FormControl>

                        <Button
                            startDecorator={loading && <CircularProgress />}
                            type="submit"
                            // onClick={verifyOTP}
                            fullWidth>
                            {loading ? t("changePin.loading") : t("changePin.confirmBtn")}
                        </Button>
                    </form>
                </ModalDialog>
            </Modal>
        </Box>
    )
}

const mapStateToProps = ({ authClient, account, guest }) => {
    const {
        termsAndConditionResult: termsResult,
        termsAndConditionStatus: termsStatus,
        termsAndConditionErrorMessage: termsErrorMessage
    } = guest
    const {
        otpResult: loginResult,
        changeUserPinResult: changeUserPinResult,
        changeUserPinStatus: changeUserPinStatus,
        changeUserPinErrorMessage: changeUserPinErrorMessage
    } = authClient
    const {
        clientDetailsStatus: clientDetailsStatus,
        clientDetailsResult: clientDetailsResult,
        clientDetailsErrorMessage: clientDetailsErrorMessage,

        requestSupportListResult: supportDataResult,
        requestSupportListStatus: supportDataStatus,
        requestSupportListErrorMessage: supportDataErrorMessage,

        profilePhotoUpdateStatus: profileStatus,
        profilePhoto: profileResult,
    } = account

    return {
        loginResult,

        changeUserPinStatus,
        changeUserPinResult,
        changeUserPinErrorMessage,

        supportDataResult,
        supportDataStatus,
        supportDataErrorMessage,

        termsResult,
        termsStatus,
        termsErrorMessage,

        clientDetailsStatus,
        clientDetailsResult,
        clientDetailsErrorMessage,

        profileStatus,
        profileResult
    }
}

export default connect(mapStateToProps, {})(ClientProfilePage)