const REQUEST = 'REQUEST';
const LOADING = 'LOADING';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';
const RESET = 'RESET';

const suffixTypes = [REQUEST, LOADING, SUCCESS, FAILURE, RESET];

function createRequestTypes(prefix = '', bases, suffixes = suffixTypes) {
    const req = {};
    bases.forEach((base) => {
        suffixes.forEach((suffix) => {
            req[`${base}_${suffix}`] = `${prefix}_${base}_${suffix}`;
        });
    });
    return req;
}

//sava application expected api states
export const CLIENT = createRequestTypes('CLIENT',
    [
        'USER_LOGIN',
        'CLIENT_OTP',
        'GUEST_LOGIN',
        'CLIENT_DETAILS',
        'LOAN_APPROVAL',
        'UNDERWRITING',
        'EDIT_LOAN_APPROVAL',
        'EDIT_UNDERWRITING',
        'AMBASSADOR_VALIDATE',
        'CHECK_AMBASSADOR',
        'USER_REGISTRATION',
        'OTP_REGISTRATION',
        'CHANGE_LANGUAGE',
        'RESEND_USER_OTP',
        'LOAN_HISTORY',
        'CHANGE_USER_PIN',
        'SUPPORT_LIST',
        'UPDATE_PROFILE_PHOTO',
        'UPLOAD_SIGNATURE',
        'PENDING_LOAN_DETAIL',
        'ACTIVE_LOAN_DETAIL',
        'PAYMENT_METHOD_LIST',
        'INITIATE_PUSH_PAYMENT',
        'TRANSACTION_HISTORIES',
        'TERMS_AND_CONDITION',
        'CLIENT_CHAT_LIST',
        'SEND_CLIENT_CHAT',
        'CLIENT_NOTIFICATION',
        'FORGET_PASSWORD',
        'AMBASSADOR_APPLICATION',
        'NIDA_VERIFICATION',
        'REFERENCE'
    ], suffixTypes);

export const ACTION_ACCOUNT_SETUP = "ACCOUNT_SETUP";
export const ACTION_ACCOUNT_REMOVE = "ACCOUNT_REMOVE";
export const ACTION_CHANGE_TO_ENGLISH = "CHANGE_TO_ENGLISH";
export const ACTION_CHANGE_TO_SWAHILI = "CHANGE_TO_SWAHILI";
export const ACTION_UPDATE_PUSH_COUNT = "UPDATE_PUSH_COUNT";
export const ACTION_RESET_PUSH_COUNT = "RESET_PUSH_COUNT";
export const ACTION_RESET_APP_STATE = "RESET_STATE";