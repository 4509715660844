import React, { useEffect, useRef, useState } from "react";
import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Box, Button, Card, CardContent, FormControl, FormLabel, Input, Select, Stack, Typography, Option, Checkbox, Link, AspectRatio, ButtonGroup, Divider, IconButton } from "@mui/joy";
import { toast } from 'react-toastify';
import { LoadingView, MapContainer, PageTitle } from "../../../components";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { clientDetailsRequest, termsAndConditionRequest, resetTermsAndConditionRequest, underwritingRequest, underwritingReset, uploadProfilePhotoReset, uploadSignatureRequest, uploadSignatureReset, editUnderwritingRequest, } from "../../../store/actions";
import { FILE_BASE, STATUS } from "../../../constant";
import { NAVIGATE_TO_HOMECLIENTPAGE, NAVIGATE_TO_TERMSANDCONDITIONPAGE } from "../../../route/types";
import { EditRounded, LocationOn, PictureInPictureAltRounded } from "@mui/icons-material";
import { NumericFormatProps } from "react-number-format";
import { useTranslation } from "react-i18next";

interface FormElements extends HTMLFormControlsCollection {
    // incomeSrc: HTMLInputElement;
    // incomeAmount: HTMLInputElement;
    loanUse: HTMLInputElement;
    guarantorName: HTMLInputElement;
    guarantorNumber: HTMLInputElement;
    guaruntorRelation: HTMLInputElement;
    checkBox: HTMLInputElement;
    creditCheck: HTMLInputElement;
}

interface SignInFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

const LoanUnderWritingPage = ({
    loginResult,

    termsResult,
    termsStatus,
    termsErrorMessage,

    clientDetailsStatus,
    clientDetailsResult,

    underWritingStatus,
    underWritingErrorMessage,
    underWritingResult,

    editUnderWritingStatus,
    editUnderWritingErrorMessage,
    editUnderWritingResult,
}) => {

    const navigate = useNavigate()
    const { state } = useLocation()
    const dispatch = useDispatch()
    const {t} = useTranslation();

    const picRef = useRef(null)
    const signRef = useRef(null)

    const { ambassador_tile, ambassador_name, loanAmount, totalAmount, selectedPlan, duration, pending_data, EdStatus, photo, nida_signature } = state


    const [profile, setProfile] = useState({
        uri: "data:image/jpeg;base64," + photo,
        file: null,
    });
    const [signature, setSignature] = useState({
        uri: "data:image/jpeg;base64," + nida_signature,
        file: null,
    })

    const [extendScr, setExtendSrc] = useState(false)
    const [incomeSrc, setIncomeSrc] = useState(EdStatus ? pending_data.underwriting.income_source : 'null')
    const [loanUse, setLoanUse] = useState({
        data: EdStatus ? pending_data.underwriting.loan_use : "",
        extend: false
    })
    const [guarantor, setGuarantor] = useState({
        name: EdStatus ? pending_data.underwriting.guarantor_name : "",
        phone: EdStatus ? pending_data.underwriting.guarantor_phone : "",
        relation: EdStatus ? pending_data.underwriting.guarantor_relation : ""
    })
    const [incomeAmount, setIncomeAmount] = useState(EdStatus ? pending_data.underwriting.monthly_income : "")

    const [collateralFields, setCollateralFields] = useState(
        EdStatus ? pending_data.collaterals.map((col) => (
            {
                collateral_name: col.name,
                collateral_value: col.value,
                collateral_picture: {
                    uri: col.picture,
                    file: null
                },
                collateral_location: {
                    latitude: parseFloat(col.latitude),
                    longitude: parseFloat(col.longitude)
                }
            }
        ))
            : [{
                collateral_name: '',
                collateral_value: '',
                collateral_picture: {
                    uri: '',
                    file: null
                },
                collateral_location: {
                    latitude: 0,
                    longitude: 0
                }
            }])


    useEffect(() => {

        if (underWritingStatus === STATUS.SUCCESS) {
            toast.success(t("underwriting.success"))
            window.open(FILE_BASE + "/" + underWritingResult.contract, "_blank"); // open contract
            setTimeout(() => {
                navigate(NAVIGATE_TO_HOMECLIENTPAGE)
            }, 1000)
            dispatch(clientDetailsRequest(loginResult.token))
            dispatch(underwritingReset());
            dispatch(uploadProfilePhotoReset())
            dispatch(uploadSignatureReset())
        }
        else if (underWritingStatus === STATUS.ERROR) {
            toast.error(underWritingErrorMessage)
            dispatch(underwritingReset());
            dispatch(uploadProfilePhotoReset())
            dispatch(uploadSignatureReset())
        }

        if (termsStatus === STATUS.SUCCESS) {
            navigate(NAVIGATE_TO_TERMSANDCONDITIONPAGE, {
                state: {
                    content: termsResult.content
                }
            })
            dispatch(resetTermsAndConditionRequest());
        }
        else if (termsStatus === STATUS.ERROR) {
            toast.error(termsErrorMessage)
            dispatch(resetTermsAndConditionRequest());
        }
    }, [underWritingStatus, termsStatus])


    const handleSubmit = (event) => {
        event.preventDefault();
        const formElements = event.currentTarget.elements;
        const data = {
            incomeSrc: incomeSrc,
            incomeAmount: incomeAmount,
            loanUse: loanUse.data,
            guarantorName: guarantor.name,
            guarantorNumber: guarantor.phone,
            guarantorRelation: guarantor.relation,
            checkBox: formElements.checkBox.checked,
            creditCheck: formElements.creditCheck.checked
            // persistent: formElements.persistent.checked,
        };

        const validateInputs = () => {
            if (data.incomeAmount == null) {
                toast.warn(t("underwriting.validation.incomeAmount"))
            } else if (data.incomeSrc == "") {
                toast.warn(t("underwriting.validation.incomeSource"))
            } else if (data.loanUse == "") {
                toast.warn(t("underwriting.validation.loanUse"))
            } else if (collateralFields[0].collateral_name == "" || collateralFields[0].collateral_value == "" || collateralFields[0].collateral_location.latitude == 0 || collateralFields[0].collateral_picture.uri == "") {
                toast.warn(t("underwriting.validation.collateral"))
            } else if (data.guarantorName == "" || data.guarantorNumber == "" || data.guarantorRelation == "") {
                toast.warn(t("underwriting.validation.guarrantor"))
            } else if (data.guarantorNumber.length < 10) {
                toast.warn(t("underwriting.validation.mobile"))
            } else if (!data.checkBox || !data.creditCheck) {
                toast.warn(t("underwriting.validation.check"))
            } else if (profile.uri == null) {
                toast.warn(t("underwriting.validation.profile"))
            } else if (signature.uri == null) {
                toast.warn(t("underwriting.validation.signature"))
            } else {
                toast.warn(t("underwriting.validation.empty"))
            }
        }
        // alert(JSON.stringify(data));
        let valid = data.checkBox && data.creditCheck && data.guarantorName && data.guarantorNumber && profile.uri != null && signature.uri != null &&
            data.guarantorRelation && data.incomeAmount && data.incomeSrc && data.loanUse &&
            collateralFields[0].collateral_name != "" && collateralFields[0].collateral_value != ""
            && collateralFields[0].collateral_location.latitude != 0 && collateralFields[0].collateral_picture.uri != ""
        // console.log("DATA: ", data, collateralFields)
        if (valid) {
            if (EdStatus) {
                console.log("EDIT DATA SENT")
                dispatch(editUnderwritingRequest(loginResult.token, pending_data.underwriting.loan_id, ambassador_tile, loanAmount, duration, selectedPlan, data.incomeSrc, data.incomeAmount,
                    data.loanUse, collateralFields, data.guarantorName, data.guarantorNumber, data.guarantorRelation))
            } else {
                dispatch(underwritingRequest(loginResult.token, ambassador_tile, loanAmount, duration, selectedPlan, data.incomeSrc, data.incomeAmount,
                    data.loanUse, collateralFields, data.guarantorName, data.guarantorNumber, data.guarantorRelation))
            }

            if (signature.file !== null) {
                dispatch(uploadSignatureRequest(loginResult.token, signature.file))
            }

        } else {
            validateInputs()
        }
    }

    // function to handle income source selections
    const handleIncomeSrc = (event, newValue) => {
        if (newValue === "Other") {
            setExtendSrc(true)
            setIncomeSrc("")
        } else {
            setExtendSrc(false)
            setIncomeSrc(newValue)
        }
    }

    // function to handle loan use list selections
    const handleLoanUse = (event, newValue) => {
        if (newValue === "Other") {
            setLoanUse({
                data: "",
                extend: true
            })
        } else {
            setLoanUse({
                data: newValue,
                extend: false
            })
        }
    }

    // FUNCTION TO GET LOCATION
    const getLocation = (index) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const updatedFields = [...collateralFields];
                    updatedFields[index]['collateral_location'] = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    }
                    setCollateralFields(updatedFields);
                },
                (error) => {
                    console.error('Error getting location:', error.message);
                    // alert(`geolocation Error: ${JSON.stringify(error)}`)
                    // toast.error(`geolocation Error: ${error.message}`)

                }
            );
        }
        else {
            alert("Geolocation is not supported with this browser, please use another browser for completion")
        }
    }

    // FUNCTION TO GET PICTURE 
    const handlePicture = (event, index) => {
        console.log("Picture pros: ", event.target.files)
        const updatedFields = [...collateralFields];
        updatedFields[index]['collateral_picture'] = {
            uri: URL.createObjectURL(event.target.files[0]),
            file: event.target.files[0]
        };
        setCollateralFields(updatedFields);
    }

    // FUNCTION TO ADD, REMOVE AND HANDLE COLLATERAL INPUTS
    const addCollateralData = () => {
        setCollateralFields([...collateralFields,
        {
            collateral_name: '',
            collateral_value: '',
            collateral_picture: {
                uri: "",
                file: null
            },
            collateral_location: {
                latitude: 0,
                longitude: 0
            }
        }])
    }

    const removeCollateralData = (index) => {
        const updateData = [...collateralFields]
        updateData.splice(index, 1);
        setCollateralFields(updateData)
    }

    const handleCollateralChange = (index, field, text) => {
        const updatedFields = [...collateralFields];
        updatedFields[index][field] = text;
        setCollateralFields(updatedFields)

    }

    // FUNCTION TO FORMAT AMOUNT  INTO THOUSAND SEPARATOR
    const formatInputValue = (value) => {
        // Remove non-numeric characters from the input value
        const numericValue = value.replace(/\D/g, '');
        // Format the numeric value with a thousand separator
        const formattedValue = parseInt(numericValue).toLocaleString('en-US');

        return formattedValue;
    };

    const viewTerms = () => {
        //navigation.navigate(NAVIGATION_LOAN_TERMS_SCREEN);
        let language = 'sw';
        dispatch(termsAndConditionRequest("term", language));
    }

    // function to check loading status and render loading view
    const checkLoading = () => {
        if (underWritingStatus === STATUS.LOADING) {
            return true
        } else {
            return false
        }
    }

    const hd = () => {
        picRef.current.click();
    }

    return (
        <Box>
            <PageTitle title={t("underwriting.title")} />
            {/* <ToastContainer /> */}

            <LoadingView loading={checkLoading()} />
            <Card variant="outlined" sx={{ backgroundColor: 'background.level1', justifyContent: 'center', alignItems: 'center' }}>
                <CardContent sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Typography level="title-lg" sx={{ color: 'background.appcolor', textAlign: 'center' }}>{t("underwriting.details")}</Typography>

                    <AspectRatio
                        variant="outlined"
                        ratio="1"
                        sx={{ width: 150, borderRadius: 8, justifyContent: 'center', alignItems: 'center' }}>
                        <img src={profile.uri} loading="lazy" />

                    </AspectRatio>
                    <AspectRatio
                        variant="outlined"
                        ratio="4"
                        sx={{ width: 150, borderRadius: 8, justifyContent: 'center', alignItems: 'center' }}>
                        {signature.uri != "" ? <img src={signature.uri} loading="lazy" /> : <label htmlFor="sign-file" style={{ color: 'green' }}>{t("underwriting.addSignature")}</label>}
                        <input type="file" id="sign-file" hidden
                            style={{ display: 'none' }}
                            onChange={(event) => setSignature(
                                (prevProfile) => ({
                                    ...prevProfile,
                                    uri: URL.createObjectURL(event.target.files[0]),
                                    file: event.target.files[0],
                                }))} />
                        {signature.uri != "" && <IconButton
                            aria-label="upload new picture"
                            size="sm"
                            variant="soft"
                            color="neutral"
                            sx={{
                                position: 'absolute',
                                height: '100%',
                                zIndex: 20,
                                borderRadius: 8,
                                right: 0,
                                bottom: 0,
                                boxShadow: 'sm',
                            }}
                        >
                            <label htmlFor="sign-file" style={{ width: '100%', alignItems: 'center' }}><EditRounded /></label>
                        </IconButton>}
                    </AspectRatio>

                    {/* form start */}
                    <Stack component='form' onSubmit={handleSubmit} noValidate gap={3} maxWidth={800} sx={{ mt: 1 }}>
                        <AccordionGroup sx={{ gap: 1 }}>
                            {/* income source */}
                            <Accordion>
                                <AccordionSummary>{t("underwriting.Q1")}</AccordionSummary>
                                <AccordionDetails>
                                    <FormControl>
                                        <Select placeholder={t("underwriting.Q1opt.placeholder")} value={incomeSrc} onChange={handleIncomeSrc} required>
                                            <Option value="Employment">{t("underwriting.Q1opt.1")}</Option>
                                            <Option value="Self-Employed/Enterpreneur">{t("underwriting.Q1opt.2")}</Option>
                                            <Option value="Freelancer/Contractor">{t("underwriting.Q1opt.3")}</Option>
                                            <Option value="Bussiness Owner">{t("underwriting.Q1opt.4")}</Option>
                                            <Option value="Pension/Retirement Income">{t("underwriting.Q1opt.5")}</Option>
                                            <Option value="Investments/Dividends">{t("underwriting.Q1opt.6")}</Option>
                                            <Option value="Student (with stipend or support)">{t("underwriting.Q1opt.7")}</Option>
                                            <Option value="Other">{t("underwriting.Q1opt.other")}</Option>
                                        </Select>
                                    </FormControl>
                                    {extendScr &&
                                        <FormControl>
                                            <FormLabel></FormLabel>
                                            <Input type="text" value={incomeSrc} onChange={(e) => setIncomeSrc(e.target.value)} required />
                                        </FormControl>}
                                </AccordionDetails>
                            </Accordion>

                            {/* income amount */}
                            <Accordion>
                                <AccordionSummary>{t("underwriting.Q2")}</AccordionSummary>
                                <AccordionDetails>
                                    <FormControl>
                                        <FormLabel></FormLabel>
                                        <Input
                                            type="text"
                                            value={incomeAmount}
                                            onChange={(event) => setIncomeAmount(formatInputValue(event.target.value))}
                                            name="incomeAmount"
                                            startDecorator={"Tsh."}
                                            required />
                                    </FormControl>
                                </AccordionDetails>
                            </Accordion>

                            {/* loan use */}
                            <Accordion>
                                <AccordionSummary>{t("underwriting.Q3")}</AccordionSummary>
                                <AccordionDetails>
                                    <FormControl>
                                        <Select placeholder={t("underwriting.Q3opt.placeholder")} value={loanUse.data} onChange={handleLoanUse} required>
                                            <Option value="Investments">{t("underwriting.Q3opt.1")}</Option>
                                            <Option value="Business Expenses">{t("underwriting.Q3opt.2")}</Option>
                                            <Option value="Home Improvement">{t("underwriting.Q3opt.3")}</Option>
                                            <Option value="Debt Consolidation">{t("underwriting.Q3opt.4")}</Option>
                                            <Option value="Education Expenses">{t("underwriting.Q3opt.5")}</Option>
                                            <Option value="Medical Expenses">{t("underwriting.Q3opt.6")}</Option>
                                            <Option value="Travel Expenses">{t("underwriting.Q3opt.7")}</Option>
                                            <Option value="Emergency Expenses">{t("underwriting.Q3opt.8")}</Option>
                                            <Option value="Personal Development">{t("underwriting.Q3opt.9")}</Option>
                                            <Option value="Technology and Gadgets">{t("underwriting.Q3opt.10")}</Option>
                                            <Option value="Legal Expenses">{t("underwriting.Q3opt.11")}</Option>
                                            <Option value="Insurance Premiums">{t("underwriting.Q3opt.12")}</Option>
                                            <Option value="Other">{t("underwriting.Q3opt.other")}</Option>
                                        </Select>
                                    </FormControl>
                                    {loanUse.extend &&
                                        <FormControl>
                                            <FormLabel></FormLabel>
                                            <Input type="text" value={loanUse.data} onChange={(e) => setLoanUse(prevData => ({ ...prevData, data: e.target.value }))} required />
                                        </FormControl>}
                                </AccordionDetails>
                            </Accordion>

                            {/* collateral */}
                            <Accordion>
                                <AccordionSummary>{t("underwriting.Q4")}</AccordionSummary>
                                <AccordionDetails>
                                    {collateralFields.map((field, index) => (
                                        <Box key={index}>
                                            <FormControl required>
                                                <FormLabel>{t("underwriting.Q4opt.name")}</FormLabel>
                                                <Input
                                                    type="text"
                                                    value={field.collateral_name}
                                                    onChange={(event) => handleCollateralChange(index, 'collateral_name', event.target.value)}
                                                    placeholder="Enter collateral name"
                                                    required />
                                            </FormControl>
                                            <FormControl required>
                                                <FormLabel>{t("underwriting.Q4opt.value")}</FormLabel>
                                                <Input
                                                    type="text"
                                                    value={field.collateral_value}
                                                    onChange={(event) => handleCollateralChange(index, 'collateral_value', formatInputValue(event.target.value))}
                                                    // slotProps={{input: {component: NumericFormatAdapter}}}
                                                    startDecorator={"Tsh."}
                                                    required />
                                            </FormControl>

                                            <Box
                                                sx={{
                                                    display: { xs: 'block', md: 'flex' },
                                                    // m: 1,
                                                    // justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}>
                                                <Box>
                                                    <FormControl>
                                                        <FormLabel>{t("underwriting.Q4opt.capture")}</FormLabel>
                                                        <input type="file" id={`col-picture${index}`} hidden onChange={(event) => handlePicture(event, index)} />

                                                        <Input
                                                            type="file"
                                                            startDecorator={
                                                                <Button variant="soft" color="neutral" startDecorator={<PictureInPictureAltRounded />}>
                                                                    <label htmlFor={`col-picture${index}`}>Choose Picture</label>
                                                                </Button>
                                                            }
                                                            sx={{
                                                                // Define internal styles for the file input
                                                                '& input[type="file"]::file-selector-button': {
                                                                    display: 'none',
                                                                },
                                                            }}
                                                            onChange={(event) => handlePicture(event, index)} required />
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel>{t("underwriting.Q4opt.location")}</FormLabel>
                                                        <Input
                                                            readOnly
                                                            placeholder={field.collateral_location.latitude == 0 ? "Click to collateral item location" : `lat: ${field.collateral_location.latitude} long: ${field.collateral_location.longitude}`}
                                                            // value={location.latitude}
                                                            startDecorator={
                                                                <Button variant="soft" color="neutral" startDecorator={<LocationOn />}>
                                                                    Locate
                                                                </Button>
                                                            }
                                                            onClick={() => getLocation(index)}
                                                        />
                                                    </FormControl>
                                                </Box>
                                                {(field.collateral_picture.uri != "" || field.collateral_location.latitude != 0) &&
                                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', m: 1, gap: 1 }}>
                                                        {field.collateral_picture.uri != "" &&
                                                            <AspectRatio
                                                                variant="outlined"
                                                                ratio="1"
                                                                sx={{ width: 150, borderRadius: 4 }}
                                                            >
                                                                <img src={field.collateral_picture.uri} loading="lazy" alt="" />
                                                            </AspectRatio>}
                                                        {field.collateral_location.latitude != 0 &&
                                                            <AspectRatio
                                                                variant="outlined"
                                                                ratio="1"
                                                                sx={{ width: 150, borderRadius: 4 }}
                                                            >
                                                                <MapContainer latitude={field.collateral_location.latitude} longitude={field.collateral_location.longitude} />
                                                            </AspectRatio>}
                                                    </Box>}


                                            </Box>

                                            <ButtonGroup sx={{ mt: 1 }}>
                                                {field.collateral_location.latitude != 0 &&
                                                    <Button onClick={addCollateralData} variant="solid" color="primary">+ {t("underwriting.add")}</Button>}
                                                {collateralFields.length > 1 &&
                                                    <Button onClick={() => removeCollateralData(index)} variant="solid" color="danger">- {t("underwriting.remove")}</Button>}
                                            </ButtonGroup>

                                            <Divider sx={{ my: 2 }} />
                                        </Box>
                                    ))}
                                </AccordionDetails>
                            </Accordion>

                            {/* guarantor */}
                            <Accordion>
                                <AccordionSummary>{t("underwriting.Q5")}</AccordionSummary>
                                <AccordionDetails>
                                    <FormControl required>
                                        <FormLabel>{t("underwriting.Q5opt.name")}</FormLabel>
                                        <Input type="text" value={guarantor.name} onChange={(event) => setGuarantor(prev => ({ ...prev, name: event.target.value }))} placeholder="Enter your guarantor name" required />
                                    </FormControl>
                                    <FormControl required>
                                        <FormLabel>{t("underwriting.Q5opt.phone")}</FormLabel>
                                        <Input type="tel" value={guarantor.phone} onChange={(event) => setGuarantor(prev => ({ ...prev, phone: event.target.value }))} placeholder="ex: 0688000001" required />
                                    </FormControl>
                                    <FormControl required>
                                        <FormLabel>{t("underwriting.Q5opt.relationship")}</FormLabel>
                                        <Input type="text" value={guarantor.relation} onChange={(event) => setGuarantor(prev => ({ ...prev, relation: event.target.value }))} placeholder="Enter your relationship with guarantor" required />
                                    </FormControl>
                                </AccordionDetails>
                            </Accordion>
                        </AccordionGroup>

                        {/* checkboc for agrement */}
                        <Box>
                            <Checkbox
                                size="md"
                                label={t("underwriting.aggrement1")}
                                name="checkBox" ></Checkbox>
                            <Link onClick={viewTerms} sx={{ color: 'green', pl: 3 }}>Terms and Conditions</Link>
                            <Checkbox
                                size="md"
                                label={t("underwriting.aggrement2")}
                                name="creditCheck" />
                        </Box>

                        <Button fullWidth type="submit">{t("underwriting.button")}</Button>
                    </Stack>

                </CardContent>
            </Card>
        </Box>
    )
}

const mapPropsToState = ({ authClient, account, guest, loan }) => {
    const {
        otpResult: loginResult
    } = authClient
    const {
        loanRequestStatus: requestStatus,
        loanRequestErrorMessage: requestErrorMessage,
        loanRequestResult: requestResult,

        underWritingStatus: underWritingStatus,
        underWritingErrorMessage: underWritingErrorMessage,
        underWritingResult: underWritingResult,

        editLoanRequestStatus: editrequestStatus,
        editLoanRequestErrorMessage: editrequestErrorMessage,
        editLoanRequestResult: editrequestResult,

        editUnderWritingStatus: editUnderWritingStatus,
        editUnderWritingErrorMessage: editUnderWritingErrorMessage,
        editUnderWritingResult: editUnderWritingResult,
    } = loan
    const {
        clientDetailsStatus: clientDetailsStatus,
        clientDetailsResult: clientDetailsResult,
    } = account
    const {
        termsAndConditionResult: termsResult,
        termsAndConditionStatus: termsStatus,
        termsAndConditionErrorMessage: termsErrorMessage
    } = guest

    return {
        loginResult,
        requestStatus,
        requestErrorMessage,
        requestResult,
        termsResult,
        termsStatus,
        termsErrorMessage,

        clientDetailsStatus,
        clientDetailsResult,

        underWritingStatus,
        underWritingErrorMessage,
        underWritingResult,

        editrequestStatus,
        editrequestErrorMessage,
        editrequestResult,

        editUnderWritingStatus,
        editUnderWritingErrorMessage,
        editUnderWritingResult,
    }
}

export default connect(mapPropsToState, {})(LoanUnderWritingPage)