import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import {
    clientLoginRequest, clientLoginReset,
    clientOTPRequest, clientOTPReset
} from "../../../store/actions"

import { CssVarsProvider } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import theme from '../../../utils/theme';
import { Avatar, Card, CircularProgress, DialogContent, DialogTitle, Modal, ModalClose, ModalDialog } from '@mui/joy';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";


import image from '../../../constant/image';
import { NAVIGATE_TO_FORGETPASSWORDPAGE, NAVIGATE_TO_HOMECLIENTPAGE, NAVIGATE_TO_REGISTRATIONPAGE } from '../../../route/types';
import { toast } from 'react-toastify';
import { STATUS } from '../../../constant';
import { LoadingView } from '../../../components';
import { ColorSchemeToggle } from '../../../utils';
import LanguageMenu from '../../../components/molecules/LanguageMenu';

interface FormElements extends HTMLFormControlsCollection {
    username: HTMLInputElement;
    password: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

const LoginPage = ({
    clientLoginStatus,
    clientLoginResult,
    clientLoginErrorMessage,

    clientOtpStatus,
    clientOtpResult,
    clientOtpErrorMessage
}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [refresh, setRefresh] = useState(false)

    const [hasEmail, setHasEmail] = useState(false);
    const [email, setEmail] = useState('');

    const [OTPModal, setOTPModal] = useState(false);
    const [loadOTP, setLoadOTP] = useState(false);
    const [OTP, setOTP] = useState('')
    const [username, setUsername] = useState('')
    useEffect(() => {

        if (clientLoginStatus === STATUS.SUCCESS) {
            setOTPModal(true)
            dispatch(clientLoginReset())
        }
        else if (clientLoginStatus === STATUS.ERROR) {
            toast.error(clientLoginErrorMessage);
            if (clientLoginErrorMessage === 'Please provide your email') {
                setHasEmail(true)
            }
            dispatch(clientLoginReset())
        }

        if (clientOtpStatus === STATUS.SUCCESS) {
            setLoadOTP(false)
            setOTPModal(false)
            navigate(NAVIGATE_TO_HOMECLIENTPAGE, { replace: true })
        }
        else if (clientOtpStatus === STATUS.ERROR) {
            setLoadOTP(false)
            toast.error(clientOtpErrorMessage)
            dispatch(clientOTPReset())
        }


    })

    // FUNCTION TO VERIFY OTP FROM  BACKEND
    const verifyOTP = (event) => {
        event.preventDefault();
        if (OTP) {
            setLoadOTP(true)
            dispatch(clientOTPRequest(username, OTP))
        } else {
            toast.error(t("login.otp.emptyErr"))
        }

    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const formElements = event.currentTarget.elements;
        const data = {
            username: formElements.username.value,
            password: formElements.password.value,
        };
        console.log(data)
        if (data.username != "" && data.password != "") {
            setUsername(data.username)
            dispatch(clientLoginRequest(data.username, data.password, email, "1234"))

        } else {
            toast.error(t("login.emptyErr"));
        }
    }

    const checkLoading = () => {
        if (clientLoginStatus == STATUS.LOADING) {
            return true
        } else {
            return false
        }
    }
    return (
        <CssVarsProvider defaultMode="light" disableTransitionOnChange theme={theme}>
            <CssBaseline />
            <GlobalStyles
                styles={{
                    ':root': {
                        '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
                        '--Cover-width': '50vw', // must be `vw` only
                        '--Form-maxWidth': '600px',
                        '--Transition-duration': '0.4s', // set to `none` to disable transition
                    },
                }}
            />

            {/* loading  */}
            <LoadingView loading={checkLoading()} />

            <Box
                sx={(theme) => ({
                    //   width: '90%',
                    transition: 'width var(--Transition-duration)',
                    transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
                    position: 'relative',
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage:
                        `url(${image.Images.backgroung})`,
                    [theme.getColorSchemeSelector('dark')]: {
                        backgroundImage:
                            `url(${image.Images.backgroung2})`,
                    },
                })}
            >

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100dvh',
                        maxWidth: '100%',
                        px: 2,
                    }}
                >
                    <Box
                        component="header"
                        sx={{
                            py: 4,
                            gap: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            // flexDirection: 'column',
                        }}
                    >
                        <Box sx={{
                            display: 'flex',
                            gap: 1,
                            alignItems: 'center'
                        }}
                        >
                            <Avatar
                                src={image.Images.logoWhite}
                                size='sm'
                                sx={{
                                    maxWidth: 80, maxHeight: 80, backgroundColor: 'primary.500', padding: '5px',
                                }}
                            />
                            <Typography level='title-lg'>SAVA</Typography>
                        </Box>

                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <LanguageMenu change={() => setRefresh(!refresh)} />
                            <ColorSchemeToggle />
                        </Box>
                    </Box>
                    <Card
                        component="main"
                        sx={{
                            my: 'auto',
                            py: 2,
                            pb: 5,
                            p: 3,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            width: 400,
                            maxWidth: '100%',
                            mx: 'auto',

                            backgroundColor: 'background.body',
                            borderRadius: 'sm',
                            '& form': {
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            },
                            [`& .${formLabelClasses.asterisk}`]: {
                                visibility: 'hidden',
                            },
                        }}
                    >
                        <Stack gap={4} sx={{ mb: 2 }}>
                            <Stack gap={1}>
                                <Typography level="h3">{t("login.title")}</Typography>
                                <Typography level="body-sm">
                                    {t("login.switch")}{' '}
                                    <Link href={NAVIGATE_TO_REGISTRATIONPAGE} sx={{ color: 'green' }} level="title-sm">
                                        {t("login.signup")}!
                                    </Link>
                                </Typography>
                            </Stack>
                        </Stack>
                        <Divider
                            sx={(theme) => ({
                                [theme.getColorSchemeSelector('light')]: {
                                    color: { xs: '#FFF', md: 'text.tertiary' },
                                    '--Divider-lineColor': {
                                        xs: '#FFF',
                                        md: 'var(--joy-palette-divider)',
                                    },
                                },
                            })}
                        />

                        <Stack component='form' onSubmit={handleSubmit} gap={4} sx={{ mt: 2 }} noValidate>
                            {/* username */}
                            <FormControl required>
                                <FormLabel>{t("login.username")}</FormLabel>
                                <Input type="text" name="username" placeholder={t("login.usernamePlaceholder")} />
                            </FormControl>

                            {/* email */}
                            {hasEmail && <FormControl>
                                <FormLabel>{t("login.email")}<i style={{ color: 'red', fontSize: 12 }}>(*{t("login.noEmail")})</i></FormLabel>
                                <Input type="email" name="usermail"
                                    // autoFocus
                                    value={email}
                                    defaultValue={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                    placeholder={t("login.emailPlaceholder")} />
                            </FormControl>}

                            {/* password */}
                            <FormControl required>
                                <FormLabel>{t("login.password")}</FormLabel>
                                <Input type="password" name="password" placeholder='***********' />
                            </FormControl>
                            <Stack gap={4} sx={{ mt: 2 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* <Checkbox size="sm" label="Remember me" name="persistent" /> */}
                                    <Link level="title-sm" sx={{ color: 'green' }} href={NAVIGATE_TO_FORGETPASSWORDPAGE}>
                                        {t("login.forgot")}
                                    </Link>
                                </Box>
                                <Button type="submit" fullWidth>
                                   {t("login.loginButton")}
                                </Button>
                            </Stack>
                        </Stack>
                    </Card>
                    <Box component="footer" sx={{ py: 3 }}>
                        <Typography level="body-xs" textAlign="center" sx={{ color: 'whitesmoke' }}>
                            {t("intro.owner")}
                        </Typography>
                        <Typography level="body-xs" textAlign="center" sx={{ color: 'whitesmoke' }}>
                            {t("intro.assistance1")}
                            <a href="tel:+255672600151" style={{ color: 'orange' }}>+255 672 600 151</a>
                            {t("intro.assistance2")}
                            <a href="mailto:support@sava.co.tz" style={{ color: 'orange' }}> support@sava.co.tz</a>
                        </Typography>
                    </Box>
                </Box>
            </Box>

            {/* OTP MODAL */}
            <Modal open={OTPModal} >
                <ModalDialog
                    aria-labelledby="nested-modal-title"
                    aria-describedby="nested-modal-description"
                    sx={(theme) => ({
                        [theme.breakpoints.only('xs')]: {  // ----------------> FOR MOBILE
                            top: 'unset',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            // borderRadius: 0,
                            borderTopLeftRadius: 20,
                            borderTopRightRadius: 20,
                            transform: 'none',
                            maxWidth: 'unset',
                        },
                    })}>
                    <ModalClose variant="outlined" component="button" onClick={() => setOTPModal(false)} />
                    <DialogTitle>{t("login.otp.title")}</DialogTitle>
                    <DialogContent>{t("login.otp.desc")}</DialogContent>
                    <form
                        onSubmit={verifyOTP}
                    >
                        <FormControl sx={{ pb: 1 }}>
                            {/* <FormLabel></FormLabel> */}
                            <Input
                                autoFocus
                                type="password"
                                required
                                value={OTP}
                                defaultValue={OTP}
                                placeholder={t("login.otp.placeholder")}
                                onChange={(event) => setOTP(event.target.value)}
                            />
                        </FormControl>

                        <Button
                            startDecorator={loadOTP && <CircularProgress />}
                            type="submit"
                            // onClick={verifyOTP}
                            fullWidth>
                            {loadOTP ? t("login.otp.loading") : t("login.otp.verifyBtn")}
                        </Button>
                    </form>
                </ModalDialog>
            </Modal>

        </CssVarsProvider>
    );
}

const mapStateToProps = ({ authClient }) => {
    const {
        userLoginStatus: clientLoginStatus,
        userLoginResult: clientLoginResult,
        userLoginErrorMessage: clientLoginErrorMessage,

        otpStatus: clientOtpStatus,
        otpResult: clientOtpResult,
        otpErrorMessage: clientOtpErrorMessage
    } = authClient

    return {

        clientLoginStatus,
        clientLoginResult,
        clientLoginErrorMessage,

        clientOtpStatus,
        clientOtpResult,
        clientOtpErrorMessage
    }
}

export default connect(mapStateToProps, {})(LoginPage)