import { call, put, take, takeLatest } from "redux-saga/effects";
import { CLIENT } from "../../constant";
import { doActiveLoanDetailRequest, doAmbassadorValidateRequest, doEditLoanRequest, doEditSendUnderwriting, doFetchReference, doInitiatePushPaymentRequest, doLoanHistoryRequest, doLoanRequest, doNidaVerification, doPendingLoanDetailRequest, doSendUnderwriting } from "../../service/loan";
import { errorMessage } from "../../utils";

function* ambassadorValidateTask(action) {
    try {
        yield put({ type: CLIENT.AMBASSADOR_VALIDATE_LOADING });

        const { payload } = action;

        const {res, decoded_data} = yield call(doAmbassadorValidateRequest, payload.token, payload.tile, payload.loan_amount);

        if (res.status == 200) {

            yield put({
                type: CLIENT.AMBASSADOR_VALIDATE_SUCCESS,
                payload: decoded_data
            });
        } else {
            const errMsg = res.data ? errorMessage(res.data.code) : errorMessage(1000);
            yield put({
                type: CLIENT.AMBASSADOR_VALIDATE_FAILURE,
                payload: errMsg
            })
        }
    } catch (e) {
        console.debug("Error here please", e);
        const errMsg = e.data ? errorMessage(e.code) : errorMessage(4000);
        yield put({
            type: CLIENT.AMBASSADOR_VALIDATE_FAILURE,
            payload: errMsg
        })
    }
}

function* underwritingRequestTask(action) {
    try {
        yield put({ type: CLIENT.UNDERWRITING_LOADING });

        const { payload } = action
        
        const {res, decoded_data} = yield call(doSendUnderwriting, payload.token, payload.tile, payload.amount,
            payload.duration, payload.pay_plan, payload.source_of_income,
            payload.monthly_income, payload.loan_use, payload.collateral,
            payload.guarantor_name, payload.guarantor_phone, payload.guarantor_relationship);

        if (res.status == 200) {
            yield put({
                type: CLIENT.UNDERWRITING_SUCCESS,
                payload: decoded_data
            });
        } else {
            const errMsg = res.data ? errorMessage(res.data.code) : errorMessage(1000);
            yield put({
                type: CLIENT.UNDERWRITING_FAILURE,
                payload: errMsg
            })
        }

    } catch (e) {
        console.debug("Error here please", e);
        const errMsg = e.data ? errorMessage(e.code) : errorMessage(4000);
        yield put({
            type: CLIENT.UNDERWRITING_FAILURE,
            payload: errMsg
        })
    }
}

function* loanRequestTask(action) {
    try {
        yield put({ type: CLIENT.LOAN_APPROVAL_LOADING });

        const { payload } = action

        const {res, decoded_data} = yield call(doLoanRequest, payload.token, payload.tile, payload.amount, payload.duration, payload.pay_plan);

        if (res.status == 200) {
            yield put({
                type: CLIENT.LOAN_APPROVAL_SUCCESS,
                payload: decoded_data
            });
        } else {
            const errMsg = res.data ? errorMessage(res.data.code) : errorMessage(1000);
            yield put({
                type: CLIENT.LOAN_APPROVAL_FAILURE,
                payload: errMsg
            })
        }

    } catch (e) {
        console.debug("Error here please", e);
        const errMsg = e.data ? errorMessage(e.code) : errorMessage(4000);
        yield put({
            type: CLIENT.LOAN_APPROVAL_FAILURE,
            payload: errMsg
        })
    }
}

function* editunderwritingRequestTask(action) {
    try {
        yield put({ type: CLIENT.EDIT_UNDERWRITING_LOADING });

        const { payload } = action
        
        const {res, decoded_data} = yield call(doEditSendUnderwriting, payload.token, payload.loan_id, payload.tile, payload.amount,
            payload.duration, payload.pay_plan, payload.source_of_income,
            payload.monthly_income, payload.loan_use, payload.collateral,
            payload.guarantor_name, payload.guarantor_phone, payload.guarantor_relationship);

        if (res.status == 200) {
            yield put({
                type: CLIENT.EDIT_UNDERWRITING_SUCCESS,
                payload: decoded_data
            });
        } else {
            const errMsg = res.data ? errorMessage(res.data.code) : errorMessage(1000);
            yield put({
                type: CLIENT.EDIT_UNDERWRITING_FAILURE,
                payload: errMsg
            })
        }

    } catch (e) {
        console.debug("Error here please", e);
        const errMsg = e.data ? errorMessage(e.code) : errorMessage(4000);
        yield put({
            type: CLIENT.EDIT_UNDERWRITING_FAILURE,
            payload: errMsg
        })
    }
}

function* editloanRequestTask(action) {
    try {
        yield put({ type: CLIENT.EDIT_LOAN_APPROVAL_LOADING });

        const { payload } = action

        const {res, decoded_data} = yield call(doEditLoanRequest, payload.token, payload.loan_id, payload.tile, payload.amount, payload.duration, payload.pay_plan);

        if (res.status == 200) {
            yield put({
                type: CLIENT.EDIT_LOAN_APPROVAL_SUCCESS,
                payload: decoded_data
            });
        } else {
            const errMsg = res.data ? errorMessage(res.data.code) : errorMessage(1000);
            yield put({
                type: CLIENT.EDIT_LOAN_APPROVAL_FAILURE,
                payload: errMsg
            })
        }

    } catch (e) {
        console.debug("Error here please", e);
        const errMsg = e.data ? errorMessage(e.code) : errorMessage(4000);
        yield put({
            type: CLIENT.EDIT_LOAN_APPROVAL_FAILURE,
            payload: errMsg
        })
    }
}

function* loanHistoryRequestTask(action) {
    try {
        yield put({ type: CLIENT.LOAN_HISTORY_LOADING });

        const { payload } = action;

        const {res, decoded_data} = yield call(doLoanHistoryRequest, payload.token);

        if (res.status == 200) {
            yield put({
                type: CLIENT.LOAN_HISTORY_SUCCESS,
                payload: decoded_data
            });
        } else {
            const errMsg = res.data ? errorMessage(res.data.code) : errorMessage(1000);
            yield put({
                type: CLIENT.LOAN_HISTORY_FAILURE,
                payload: errMsg
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage(e.code) : errorMessage(4000);
        yield put({
            type: CLIENT.LOAN_HISTORY_FAILURE,
            payload: errMsg
        })
    }
}

function* pendingLoanDetailRequestTask(action) {
    try {
        yield put({ type: CLIENT.PENDING_LOAN_DETAIL_LOADING });

        const { payload } = action;

        const {res, decoded_data} = yield call(doPendingLoanDetailRequest, payload.token);

        if (res.status == 200) {
            yield put({
                type: CLIENT.PENDING_LOAN_DETAIL_SUCCESS,
                payload: decoded_data
            });
        } else {
            const errMsg = res.data ? errorMessage(res.data.code) : errorMessage(1000);
            yield put({
                type: CLIENT.PENDING_LOAN_DETAIL_FAILURE,
                payload: errMsg
            })
        }

    } catch (e) {
        const errMsg = e.data ? errorMessage(e.code): errorMessage(4000);
        yield put({
            type: CLIENT.PENDING_LOAN_DETAIL_FAILURE,
            payload: errMsg
        })
    }
}

function* activeLoanDetailRequestTask(action) {
    try {
        yield put({ type: CLIENT.ACTIVE_LOAN_DETAIL_LOADING });

        const { payload } = action;

        const {res, decoded_data} = yield call(doActiveLoanDetailRequest, payload.token);

        if (res.status == 200) {
            yield put({
                type: CLIENT.ACTIVE_LOAN_DETAIL_SUCCESS,
                payload: decoded_data
            });
        } else {
            const errMsg = res.data ? errorMessage(res.data.code) : errorMessage(1000);
            yield put({
                type: CLIENT.ACTIVE_LOAN_DETAIL_FAILURE,
                payload: errMsg
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage(e.code) : errorMessage(4000);
        yield put({
            type: CLIENT.ACTIVE_LOAN_DETAIL_FAILURE,
            payload: errMsg
        })
    }
}

function* initiatePushPaymentTask(action) {
    try {
        yield put({ type: CLIENT.INITIATE_PUSH_PAYMENT_LOADING });

        const { payload } = action;

        const {res, decoded_data} = yield call(doInitiatePushPaymentRequest, payload.token, payload.amount);

        if (res.status == 200) {
            yield put({
                type: CLIENT.INITIATE_PUSH_PAYMENT_SUCCESS,
                payload: decoded_data
            });
        } else {
            const errMsg = res.data ? errorMessage(res.data.code) : errorMessage(1000);
            yield put({
                type: CLIENT.INITIATE_PUSH_PAYMENT_FAILURE,
                payload: errMsg
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage(e.code) : errorMessage(4000);
        yield put({
            type: CLIENT.INITIATE_PUSH_PAYMENT_FAILURE,
            payload: errMsg
        })
    }
}

function* nidaVerificationTask(action) {
    try {
        yield put({ type: CLIENT.NIDA_VERIFICATION_LOADING });

        const { payload } = action;

        const {res, decoded_data} = yield call(doNidaVerification, payload.token, payload.data);

        if (res.status == 200) {
            yield put({
                type: CLIENT.NIDA_VERIFICATION_SUCCESS,
                payload: decoded_data
            });
        } else {
            const errMsg = res.data ? errorMessage(res.data.code) : errorMessage(1000);
            yield put({
                type: CLIENT.NIDA_VERIFICATION_FAILURE,
                payload: errMsg
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage(e.code) : errorMessage(4000);
        yield put({
            type: CLIENT.NIDA_VERIFICATION_FAILURE,
            payload: errMsg
        })
    }
}

function* referenceTask(action) {
    try {
        yield put({ type: CLIENT.REFERENCE_LOADING });

        const { payload } = action;

        const {res, decoded_data} = yield call(doFetchReference, payload.token, payload.data);

        if (res.status == 200) {
            yield put({
                type: CLIENT.REFERENCE_SUCCESS,
                payload: decoded_data
            });
        } else {
            const errMsg = res.data ? errorMessage(res.data.code) : errorMessage(1000);
            yield put({
                type: CLIENT.REFERENCE_FAILURE,
                payload: res.message
            })
        }
    } catch (e) {
        const errMsg = e.data ? errorMessage(e.code) : errorMessage(4000);
        yield put({
            type: CLIENT.REFERENCE_FAILURE,
            payload: errMsg
        })
    }
}

function* loanSaga() {
    yield takeLatest(CLIENT.AMBASSADOR_VALIDATE_REQUEST, ambassadorValidateTask);
    yield takeLatest(CLIENT.UNDERWRITING_REQUEST, underwritingRequestTask);
    yield takeLatest(CLIENT.LOAN_APPROVAL_REQUEST, loanRequestTask);
    yield takeLatest(CLIENT.EDIT_UNDERWRITING_REQUEST, editunderwritingRequestTask);
    yield takeLatest(CLIENT.EDIT_LOAN_APPROVAL_REQUEST, editloanRequestTask);
    yield takeLatest(CLIENT.LOAN_HISTORY_REQUEST, loanHistoryRequestTask);
    yield takeLatest(CLIENT.PENDING_LOAN_DETAIL_REQUEST, pendingLoanDetailRequestTask);
    yield takeLatest(CLIENT.ACTIVE_LOAN_DETAIL_REQUEST, activeLoanDetailRequestTask);
    yield takeLatest(CLIENT.INITIATE_PUSH_PAYMENT_REQUEST, initiatePushPaymentTask);
    yield takeLatest(CLIENT.NIDA_VERIFICATION_REQUEST, nidaVerificationTask);
    yield takeLatest(CLIENT.REFERENCE_REQUEST, referenceTask);
}

export default loanSaga;