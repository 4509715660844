import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardActions, CardContent, Checkbox, CircularProgress, DialogContent, DialogTitle, FormControl, FormLabel, Input, List, ListItem, Modal, ModalClose, ModalDialog, Slider, Tooltip, Typography, checkboxClasses } from "@mui/joy";
import { toast } from 'react-toastify';
import Divider from '@mui/joy/Divider';
import { LoadingView, PageTitle } from "../../../components";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import thousandSeparator from "../../../utils/thousandSeparator";
import { NAVIGATE_TO_CLIENTLOANCOMPLETEPAGE, NAVIGATE_TO_LOANDESCRIPTIONPAGE, NAVIGATE_TO_LOANUNDERWRITINGPAGE, NAVIGATE_TO_NIDAVERIFICATIONPAGE } from "../../../route/types";
import { InfoRounded, RemoveRedEyeRounded } from "@mui/icons-material";

import { fetchSupportContent, fetchSupportContentReset, ambassadorValidateRequest, ambassadorValidateReset } from "../../../store/actions"
import { STATUS } from "../../../constant";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n/i18n.js";

interface FormElements extends HTMLFormControlsCollection {
    ambassadorTill: HTMLInputElement;
    // persistent: HTMLInputElement;
}

interface SignInFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

const ClientLoanRequestPage = ({
    loginResult,
    supportDataResult,
    supportDataStatus,
    supportDataErrorMessage,

    requestStatus,
    requestResult,
    requestErrorMessage
}) => {

    const navigate = useNavigate()
    const { state } = useLocation()
    const dispatch = useDispatch()
    const { t } = useTranslation();

    const { pending_data, EdStatus } = state

    const { payment_plans, maximum_limit, sava_min_amount, client_name, token } = loginResult;
    const [amount, setAmount] = useState(EdStatus ? parseInt(pending_data.amount) : parseInt(maximum_limit) / 2);
    const [selectedPlan, setSelectedPlan] = useState(EdStatus ? String(pending_data.payment_plan) : 0)
    const [loanDuration, setLoanDuration] = useState(61);
    const [loanFee, setLoanFee] = useState(0);
    const [totalLoan, setTotalLoan] = useState(0);
    const [cycleCount, setCycleCount] = useState(0);
    const [FeeAndLoan, setFeeAndLoan] = useState(0);
    const [insurance, setInsurance] = useState(0);
    const [rates, setRates] = useState({ fee: 0, insurance: 0 })

    const [loanRate, setLoanRate] = useState(0);

    const [ambassadorModal, setAmbassadorModal] = useState(false);
    const [loadAmbassador, setLoadAmbassador] = useState(false);
    const [ambassadorData, setAmbassadorData] = useState({
        name: '',
        tile: "",
        underwriting: false,
        nida_status: 0,
        photo: "",
        signature: "",
        username: "",
    })

    const [repaymentModal, setRepaymentModal] = useState(false)

    useEffect(() => {
        if (supportDataStatus === STATUS.SUCCESS) {
            navigate(NAVIGATE_TO_LOANDESCRIPTIONPAGE, {
                state: {
                    content: supportDataResult.content
                }

            });
            dispatch(fetchSupportContentReset());
        }
        else if (supportDataStatus === STATUS.ERROR) {
            toast.error("Failed to fetch loan descriptions")
            dispatch(fetchSupportContentReset());
        }

        if (requestStatus === STATUS.SUCCESS) {
            //dropDownAlertRef.alertWithType('success', 'success', 'Congratulation');
            setLoadAmbassador(false)
            // setAmbassadorModal(false)
            setAmbassadorData({
                name: requestResult.ambassador_name,
                tile: requestResult.ambassador_tile,
                underwriting: requestResult.underwriting_qns,
                nida_status: requestResult.nida_status,
                photo: requestResult.photo,
                signature: requestResult.signature,
                username: requestResult.name
                // underwriting: true
            })
            dispatch(ambassadorValidateReset());
        } else if (requestStatus === STATUS.ERROR) {
            setLoadAmbassador(false)
            toast.error(requestErrorMessage)
            dispatch(ambassadorValidateReset());
        }
    }, [supportDataStatus, requestStatus]);

    // FUNCTION TO VERIFY AMBASSADOR AND NAVIGATE TO NEXT PAGE
    const handleSubmitTill = (event) => {
        event.preventDefault();
        const formElements = event.currentTarget.elements;
        const data = {
            ambassadorTill: formElements.ambassadorTill.value,
            // password: formElements.password.value,
            // persistent: formElements.persistent.checked,
        };
        // console.log(data)
        if (data.ambassadorTill !== "") {
            if (ambassadorData.name === "") {
                setLoadAmbassador(true)
                dispatch(ambassadorValidateRequest(token, data.ambassadorTill, amount))
            } else {
                setLoadAmbassador(false);
                setAmbassadorModal(false);
                if (ambassadorData.nida_status === 2) {
                    navigate(NAVIGATE_TO_LOANUNDERWRITINGPAGE, {
                        state: {
                            loanAmount: amount,
                            totalAmount: totalLoan,
                            duration: cycleCount + " " + loanDuration,
                            selectedPlan: selectedPlan,
                            ambassador_name: ambassadorData.name,
                            ambassador_tile: ambassadorData.tile,
                            pending_data: pending_data,
                            EdStatus: EdStatus,
                            photo: ambassadorData.photo,
                            nida_signature: ambassadorData.signature,
                            name: ambassadorData.name
                            // underwriting: ambassadorData.underwriting,
                            // underwriting: "true"
                        }
                    })
                } else if (ambassadorData.nida_status !== 2) {
                    navigate(NAVIGATE_TO_NIDAVERIFICATIONPAGE, {
                        state: {
                            loanAmount: amount,
                            totalAmount: totalLoan,
                            duration: cycleCount + " " + loanDuration,
                            selectedPlan: selectedPlan,
                            ambassador_name: ambassadorData.name,
                            ambassador_tile: ambassadorData.tile,
                            pending_data: pending_data,
                            EdStatus: EdStatus
                        }
                    })
                } else {
                    toast.warn("have some trouble please try again")
                }
            }

        } else {
            toast.error(t("ambassadorForm.emptyErr"));
        }
    };

    useEffect(() => {
        if (EdStatus && ambassadorData.name !== "") {
            if (pending_data.underwriting !== null) {
                navigate(NAVIGATE_TO_LOANUNDERWRITINGPAGE, {
                    state: {
                        loanAmount: amount,
                        totalAmount: totalLoan,
                        duration: cycleCount + " " + loanDuration,
                        selectedPlan: selectedPlan,
                        ambassador_name: ambassadorData.name,
                        ambassador_tile: ambassadorData.tile,
                        pending_data: pending_data,
                        EdStatus: EdStatus
                        // underwriting: ambassadorData.underwriting,
                        // underwriting: "true"
                    }
                })
            } else {
                navigate(NAVIGATE_TO_CLIENTLOANCOMPLETEPAGE, {
                    state: {
                        loanAmount: amount,
                        totalAmount: totalLoan,
                        duration: cycleCount + " " + loanDuration,
                        selectedPlan: selectedPlan,
                        ambassador_name: ambassadorData.name,
                        ambassador_tile: ambassadorData.tile,
                        pending_data: pending_data,
                        EdStatus: EdStatus
                    }
                })
            }
        }

    }, [EdStatus, ambassadorData.name])

    // FUNCTION TO OPEN AMBASSADOR MODAL
    const openModal = () => {
        if (selectedPlan && amount) {
            setAmbassadorModal(true);

        } else {
            alert(t("loanRequest.loanDurationAlert"));
        }
    }

    // ---- Formular to Calculate Installment with Reduce Methode
    const calculateInstallmentAmount = () => {
        let _selectedPlan = payment_plans.find(p => p.id === selectedPlan)
        const principal = amount;
        const rate = parseInt(_selectedPlan.rate) / 100;
        const n = parseInt(_selectedPlan.cycle_count);

        const numerator = principal * rate * Math.pow((1 + rate), n);
        const denominator = Math.pow((1 + rate), n) - 1;
        const result = numerator / denominator;
        // console.log("Payment per month: ", result)

        // Round to the nearest thousand
        const roundedResult = Math.round(result / 1000) * 1000;
        const total_interest = (roundedResult * n) - principal
        const monthly_interest = total_interest / n
        const monthly_principal = principal / n

        return {
            _installment_amount: roundedResult,
            total_repayment: roundedResult * parseInt(_selectedPlan.cycle_count),
            total_interest,
            monthly_interest,
            monthly_principal
        };

    };

    useEffect(() => {
        // changeLoanFee(parseInt(amount))
        let _selectedPlan = payment_plans.find(p => p.id === selectedPlan)
        if (_selectedPlan != null) {
            const fee = (parseFloat(_selectedPlan.fee_rate) / 100) * amount;
            const insurance_rate = (parseFloat(_selectedPlan.insurance_rate) / 100) * amount;
            setLoanFee(Math.trunc(fee));
            setInsurance(insurance_rate);
            setRates({ fee: _selectedPlan.fee_rate, insurance: _selectedPlan.insurance_rate })
            setLoanRate(parseInt(_selectedPlan.rate));
            setCycleCount(parseInt(_selectedPlan.cycle_count));
            setLoanDuration(_selectedPlan.duration);
            let total_loan_and_fee = amount + loanFee
            let _loanInterestAmount = parseInt(_selectedPlan.rate) * total_loan_and_fee * _selectedPlan.cycle_count / 100;
            // setTotalLoan(parseInt(total_loan_and_fee) + _loanInterestAmount);
            setTotalLoan(calculateInstallmentAmount().total_repayment)
            setFeeAndLoan(total_loan_and_fee)
        }
    }, [amount, loanFee, selectedPlan])

    // function to store amount in state 
    const handleSliderChange = (event, newValue) => {
        setAmount(newValue);
        // You can perform any additional actions here with the new value
    };

    // function to direct to payment plan page
    const viewRepaymentPlan = () => {
        if (!selectedPlan) {
            alert("Please select payment plan");
            return;
        }
        setRepaymentModal(true)
    }

    // loan info decription data call
    const viewLoanDescription = () => {
        let category = "description";
        let language = i18n.language;
        dispatch(fetchSupportContent(token, language, category));
    }

    // PLAN LIST RENDERING
    const planList = () => {
        return (
            <List
                sx={{
                    '--ListItem-gap': '0.75rem',
                    width: { xs: '100%', md: '45%' },
                    gap: 0.5,
                    [`& .${checkboxClasses.root}`]: {
                        mr: 'auto',
                        flexGrow: 1,
                        alignItems: 'center',
                        flexDirection: 'row-reverse',
                    },

                }}>
                <Typography level="title-sm">{t("loanRequest.select")}</Typography>
                {payment_plans.map((plan) => {
                    // console.log(plan)
                    const selectPlan = (event) => {
                        setSelectedPlan(event.target.value)

                    }
                    return (
                        <ListItem
                            variant="outlined"
                            sx={{
                                backgroundColor: selectedPlan === plan.id ? 'background.level3' : 'transparent',
                                borderRadius: 10,
                            }}>
                            <Checkbox
                                overlay
                                checked={selectedPlan === plan.id}
                                value={plan.id}
                                onChange={selectPlan}
                                label={
                                    <React.Fragment>
                                        <Typography level="body-sm" sx={{ color: 'text.primary' }}>{plan.name}</Typography>
                                        <Typography level="body-sm" sx={{ display: 'block' }}>{plan.cycle_count + " " + plan.duration}</Typography>
                                    </React.Fragment>
                                } />
                        </ListItem>
                    )
                })}
            </List>
        )

    }

    // REPAYMENT PLAN MODAL 
    const repaymentModa = () => {
        const calculateDiscountDate = (duration) => {
            var date = new Date();
            date.setDate(date.getDate() + duration);
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
        }

        const received_amount = amount - (loanFee + insurance);

        const renderPayPlan = () => {
            let _installment_amount = (totalLoan / cycleCount);
            let month_days = 30;
            let _view = [];

            _view.push(
                <>
                    <Box
                        key={0}
                        sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                        }}>
                        <Typography level="title-sm">{t("repaymentPlan.plan")}</Typography>
                        <Typography level="title-sm">{t("repaymentPlan.int")}</Typography>
                        <Typography level="title-sm">{t("repaymentPlan.principal")}</Typography>
                        <Typography level="title-sm">{t("repaymentPlan.install")}</Typography>
                    </Box>
                    <Divider />
                </>
            );

            for (var count = 1; count <= cycleCount; count++) {
                _view.push(
                    <>
                        <Box
                            key={count}
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                pt: 1
                            }}>
                            <Typography level="body-sm">{calculateDiscountDate(month_days * count)}</Typography>
                            <Typography level="body-sm" textAlign='right'>{"Tsh. " + thousandSeparator(calculateInstallmentAmount().monthly_interest)}</Typography>
                            <Typography level="body-sm" textAlign='right'>{"Tsh. " + thousandSeparator(calculateInstallmentAmount().monthly_principal)}</Typography>
                            <Typography level="body-sm" textAlign='right'>{"Tsh. " + thousandSeparator(calculateInstallmentAmount()._installment_amount)}</Typography>

                        </Box>
                        <Divider />
                    </>
                )
            }
            return _view;
        }

        const loan_Details = [
            { title: t("repaymentPlan.loan"), content: "Tsh. " + thousandSeparator(amount) },
            { title: t("repaymentPlan.fee") + " " + rates.fee + "%", content: "Tsh. " + thousandSeparator(loanFee) },
            { title: t("repaymentPlan.insuarance") + "0" + rates.insurance + "%", content: "Tsh. " + thousandSeparator(insurance) },
            { title: t("repaymentPlan.receive"), content: "Tsh. " + thousandSeparator(received_amount) },
            { title: t("repaymentPlan.intperMonth"), content: loanRate + "%" },
            { title: t("repaymentPlan.duration"), content: cycleCount + " " + loanDuration },
            { title: t("repaymentPlan.interest"), content: "Tsh. " + thousandSeparator(calculateInstallmentAmount().total_interest) },
            { title: t("repaymentPlan.pay"), content: "Tsh. " + thousandSeparator(totalLoan) }
        ]

        return (
            <Modal open={repaymentModal} onClose={() => setRepaymentModal(false)}>
                <ModalDialog
                    aria-labelledby="nested-modal-title"
                    aria-describedby="nested-modal-description"
                    size="md"
                    sx={(theme) => ({
                        [theme.breakpoints.only('xs')]: {
                            top: 'unset',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            // borderRadius: 0,
                            borderTopLeftRadius: 20,
                            borderTopRightRadius: 20,
                            transform: 'none',
                            maxWidth: 'unset',
                        },
                        width: { xs: "100%", md: "45%" }
                    })}>
                    <ModalClose variant="outlined" />
                    <DialogTitle>{t("repaymentPlan.title")}</DialogTitle>
                    <DialogContent>
                        <Typography level="title-md" textAlign={'center'}>{t("repaymentPlan.details")}</Typography>
                        {loan_Details.map((details) => (
                            <>
                                <Box
                                    key={0}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        pt: 1
                                    }}>
                                    <Typography level="body-sm">{details.title}</Typography>
                                    <Typography level="body-sm" textAlign='right'><b>{details.content}</b></Typography>

                                </Box>
                                <Divider />
                            </>

                        ))}
                    </DialogContent>
                    <DialogContent>
                        <Typography level="title-md" textAlign={'center'}>{t("repaymentPlan.schedule")}</Typography>
                        {renderPayPlan()}
                    </DialogContent>

                </ModalDialog>
            </Modal>
        )

    }


    // function to check loading...
    const checkLoading = () => {
        if (supportDataStatus === STATUS.LOADING) {
            return true
        }
        else {
            return false
        }
    }

    // MAIN RETURN
    return (
        <Box>
            <PageTitle title={t("loanRequest.title")} />
            {/* <Typography variant="div"> */}
            {/* <ToastContainer /> */}

            <LoadingView loading={checkLoading()} />
            <Card variant="outlined" sx={{ backgroundColor: 'background.level1' }}>
                <CardContent>
                    {/* loan amount */}
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'

                    }}>
                        <Tooltip title='Loan info description'>
                            <Button variant="plain"
                                onClick={() => { viewLoanDescription() }}
                                sx={{ color: 'background.appcolor', position: 'absolute', top: 10, right: 20 }} >
                                <InfoRounded />
                            </Button>
                        </Tooltip>
                        <Typography level="title-md" textAlign={'center'}>
                            Choose Loan Amount
                        </Typography>
                        <Typography level="h1" textAlign={'center'}>
                            {thousandSeparator(amount)} Tsh
                        </Typography>
                        <Slider
                            aria-label="Small steps"
                            defaultValue={amount}
                            value={amount}
                            onChange={handleSliderChange}
                            step={10000}
                            min={parseInt(sava_min_amount)}
                            max={parseInt(maximum_limit)}
                            valueLabelDisplay="auto"
                            sx={{
                                width: { xs: '80%', md: '70%' },
                            }}
                        />
                    </Box>


                    <Box sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        gap: 2
                    }}>
                        {/* loan duration */}
                        {planList()}

                        <Divider orientation="vertical" sx={{ display: { xs: 'none', md: 'block' } }} />

                        {/* Loan Details */}
                        <Box
                            sx={{
                                width: { xs: '100%', md: '50%' },
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                // alignItems: 'center',
                                gap: 0.5
                            }}>
                            <Typography level="title-sm">{t("loanRequest.loanDetails")}</Typography>
                            <Box sx={{
                                justifyContent: 'space-between',
                                display: 'flex',

                            }}>
                                <Typography level="body-sm">{t("loanRequest.requestAmount")}</Typography>
                                <Typography level="body-sm">{thousandSeparator(amount)} Tsh</Typography>
                            </Box>
                            <Divider />

                            <Box sx={{
                                justifyContent: 'space-between',
                                display: 'flex',

                            }}>
                                <Typography level="body-sm">{t("loanRequest.fee")} {rates.fee + "%"}</Typography>
                                <Typography level="body-sm">{thousandSeparator(loanFee)} Tsh</Typography>
                            </Box>
                            <Divider />

                            <Box sx={{
                                justifyContent: 'space-between',
                                display: 'flex',

                            }}>
                                <Typography level="body-sm">{t("loanRequest.insurance")} {rates.insurance + "%"}</Typography>
                                <Typography level="body-sm">{thousandSeparator(insurance)} Tsh</Typography>
                            </Box>
                            <Divider />

                            <Box sx={{
                                justifyContent: 'space-between',
                                display: 'flex',

                            }}>
                                <Typography level="body-sm">{t("loanRequest.rate")}</Typography>
                                <Typography level="body-sm">{loanRate}%</Typography>
                            </Box>
                            <Divider />

                            {/* <Link to={NAVIGATE_TO_REPAYMENTPLANPAGE}> */}
                            <Button
                                startDecorator={<RemoveRedEyeRounded />}
                                variant="outlined"
                                sx={{ alignSelf: 'center', marginTop: '10px', color: 'background.appcolor' }}
                                onClick={viewRepaymentPlan}>
                                {t("loanRequest.return")}
                            </Button>
                        </Box>
                    </Box>

                </CardContent>
                <CardActions>
                    <Button
                        startDecorator={requestStatus === STATUS.LOADING && <CircularProgress />}
                        onClick={() => EdStatus ? dispatch(ambassadorValidateRequest(token, pending_data.ambassador_tile, amount)) : openModal()}>
                        {(EdStatus && requestStatus === STATUS.LOADING) ? t("loanRequest.loading") : t("loanRequest.next")}
                    </Button>
                </CardActions>
            </Card>

            {/* AMBASSODAR MODAL */}
            <Modal open={ambassadorModal} >
                <ModalDialog
                    aria-labelledby="nested-modal-title"
                    aria-describedby="nested-modal-description"
                    sx={(theme) => ({
                        [theme.breakpoints.only('xs')]: {
                            top: 'unset',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            // borderRadius: 0,
                            borderTopLeftRadius: 20,
                            borderTopRightRadius: 20,
                            transform: 'none',
                            maxWidth: 'unset',
                        },
                    })}>
                    <ModalClose variant="outlined" onClick={() => setAmbassadorModal(false)} />
                    <DialogTitle>{t("ambassadorForm.title")}</DialogTitle>
                    <DialogContent>{t("ambassadorForm.desc")}</DialogContent>
                    <form
                        onSubmit={handleSubmitTill}
                    >
                        <FormControl sx={{ pb: 1 }}>
                            <FormLabel>{t("ambassadorForm.till")}</FormLabel>
                            <Input
                                autoFocus
                                type="number"
                                required
                                name="ambassadorTill"
                            />
                            {ambassadorData.name !== "" && (
                                <>
                                    <Typography>{t("ambassadorForm.ambassadorName")}: <b>{ambassadorData.name}</b></Typography>
                                    <Typography>{t("ambassadorForm.ambassadorTile")}: <b>{ambassadorData.tile}</b></Typography>
                                </>

                            )}

                        </FormControl>

                        <Button
                            startDecorator={loadAmbassador && <CircularProgress />}
                            type="submit"
                            fullWidth>
                            {loadAmbassador ? t("ambassadorForm.loading") : (ambassadorData.name !== "" ? t("ambassadorForm.continue") : t("ambassadorForm.confirmButton"))}
                        </Button>
                        {ambassadorData.name !== "" && <Button
                            variant="soft"
                            onClick={() => setAmbassadorData({
                                name: '',
                                tile: '',
                                underwriting: false,
                                nida_status: 0,
                                photo: '',
                                signature: '',
                                username: ''
                            })}
                            fullWidth
                            sx={{ mt: 1 }}>
                            {t("ambassadorForm.reset")}
                        </Button>}
                    </form>
                </ModalDialog>
            </Modal>

            {/* repayment modal call */}
            {selectedPlan && repaymentModa()}

        </Box>
    )
}

const mapStateToProps = ({ authClient, account, loan }) => {
    const {
        otpResult: loginResult
    } = authClient
    const {
        requestSupportListResult: supportDataResult,
        requestSupportListStatus: supportDataStatus,
        requestSupportListErrorMessage: supportDataErrorMessage,
    } = account
    const {
        ambassadorValidateStatus: requestStatus,
        ambassadorValidateErrorMessage: requestErrorMessage,
        ambassadorResult: requestResult
    } = loan

    return {
        loginResult,
        supportDataResult,
        supportDataStatus,
        supportDataErrorMessage,

        requestStatus,
        requestResult,
        requestErrorMessage
    }
}
export default connect(mapStateToProps, {})(ClientLoanRequestPage)