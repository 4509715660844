import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, CircularProgress, Divider, FormControl, FormLabel, Input, List, ListItem, Typography } from "@mui/joy";
import { toast } from 'react-toastify';
import { PageTitle } from "../../../components";
import { useLocation, useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { NidaFormat } from "../../../utils";
import { nidaVerificationRequest, nidaVerificationReset, referenceRequest, referenceReset } from '../../../store/actions'
import { STATUS } from "../../../constant";
import { useTranslation } from "react-i18next";

const NidaVerificationPage = ({
    loginResult,
    nidaStatus,
    nidaResult,
    nidaErrorMessage,
    referenceStatus,
    referenceResult,
    referenceErrorMessage,
    clientDetailsStatus,
    clientDetailsResult
}) => {

    const navigate = useNavigate()
    const { state } = useLocation()
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const { checkNidaStatus } = state

    const [nida, setNida] = useState(loginResult.nida);

    const [reference, setRerefence] = useState(clientDetailsResult.token_ref || "");
    const [status, setStatus] = useState("");
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (clientDetailsStatus === STATUS.SUCCESS) {
            setNida(clientDetailsResult.message.nida)
        }
        if (nidaStatus === STATUS.SUCCESS) {
            setRerefence(nidaResult.token_ref);
            setShow(true);
            dispatch(referenceRequest(loginResult.token, { "token_ref": nidaResult.token_ref }));
            dispatch(nidaVerificationReset());
        }
        else if (nidaStatus === STATUS.ERROR) {
            dispatch(referenceRequest(loginResult.token, { "token_ref": reference }));
            toast.error(nidaErrorMessage);
            dispatch(nidaVerificationReset());
        }

        if (referenceStatus === STATUS.SUCCESS) {
            setRerefence(referenceResult.token_ref);
            setStatus(referenceResult.message);
            setShow(true)
            dispatch(referenceReset());
        }
        else if (referenceStatus === STATUS.ERROR) {
            toast.error(referenceErrorMessage);
            dispatch(referenceReset());
        }
    }, [nidaStatus, referenceStatus])

    useEffect(() => {
        if (checkNidaStatus) {
            dispatch(referenceRequest(loginResult.token, { "token_ref": reference }))
        }
    }, [checkNidaStatus])

    const verifyNida = (event) => {
        event.preventDefault();
        if (show) {
            dispatch(referenceRequest(loginResult.token, { "token_ref": reference }))
        }
        else {
            if (nida) {
                dispatch(nidaVerificationRequest(loginResult.token, { "nida": nida }));
            }
            else {
                toast.error(t("nida.emptyErr"))
            }
        }
    }

    const checkLoading = () => {
        if (nidaStatus === STATUS.LOADING || referenceStatus === STATUS.LOADING) {
            return true
        } else {
            return false
        }
    }
    return (
        <Box>
            <PageTitle title={t("nida.title")} />

            <Card variant="outlined" sx={{ backgroundColor: 'background.level1', justifyContent: 'center', alignItems: 'center' }}>
                <CardContent
                    sx={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: { xs: 'column', md: "row" }
                    }}>
                    <Box
                        sx={{
                            width: { xs: '100%', md: '40%' },
                            gap: 2
                        }}>

                        <Typography level="body-sm" sx={{ backgroundColor: 'background.level3', p: 1, mb: 1 }}>
                            {show
                                ? t("nida.desc1")
                                : t("nida.desc2")
                            }
                        </Typography>

                        <form onSubmit={verifyNida}>
                            <FormControl sx={{ pb: 3 }}>
                                <FormLabel>{t("nida.number")}</FormLabel>
                                <Input
                                    value={nida}
                                    onChange={(e) => setNida(e.target.value)}
                                    name="nida"
                                    disabled={show}
                                    placeholder={t("nida.ref")}
                                    slotProps={{ input: { component: NidaFormat } }}
                                />
                            </FormControl>
                            <Button color="success" type="submit" fullWidth sx={{ borderRadius: 100 }}>{show ? t("nida.refresh") : t("nida.verify")}</Button>
                        </form>
                    </Box>
                    <Divider orientation="vertical" sx={{ display: { xs: 'none', md: 'block' } }} />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: { xs: '100%', md: '60%' },
                            mt: { xs: 5, md: 0 },
                            // justifyContent: 'center',
                            // alignItems: 'center'
                        }}>
                        {checkLoading() ? <CircularProgress color="warning" size='lg' thickness={3} variant="outlined" sx={{ alignSelf: 'center', mb: 1 }} />
                            : (show && <Box
                                sx={{
                                    backgroundColor: "background.level2",
                                    borderWidth: 1,
                                    // borderColor: 'gray',
                                    mb: 1,
                                    p: 1
                                }}>
                                <Typography><b>{t("nida.ref")}:</b> {reference}</Typography>
                                <Typography><b>{t("nida.status")}:</b> {status}</Typography>
                            </Box>)}

                        <Divider />

                        <Typography level="title-md">{t("nida.stepsTitle")}</Typography>
                        <List
                            marker="decimal"
                            size="sm"
                            sx={{
                                "--List-gap": "-8px"
                            }}>
                            <ListItem>{t("nida.steps.1")}</ListItem>
                            <ListItem>{t("nida.steps.2")}</ListItem>
                            <ListItem>{t("nida.steps.3")} <a href="https://play.google.com/store/apps/details?id=net.fingerscan.tbridge" target="_blank" style={{ color: 'blue' }}>Fingerprint Scan</a></ListItem>
                            <ListItem>{t("nida.steps.4")}</ListItem>
                            <ListItem>{t("nida.steps.5")}</ListItem>
                            <ListItem>{t("nida.steps.6")}</ListItem>
                        </List>
                        <Typography level="title-sm">{t("nida.steps.NOTE")}</Typography>
                    </Box>

                </CardContent>
            </Card>
        </Box>
    )
}


const mapStateToProps = ({ loan, authClient, account }) => {
    const {
        otpResult: loginResult
    } = authClient
    const {
        clientDetailsStatus,
        clientDetailsResult: clientDetailsResult
    } = account
    const {
        nidaStatus,
        nidaResult,
        nidaErrorMessage,

        referenceStatus,
        referenceResult,
        referenceErrorMessage
    } = loan
    return {
        loginResult,
        nidaStatus,
        nidaResult,
        nidaErrorMessage,
        referenceStatus,
        referenceResult,
        referenceErrorMessage,
        clientDetailsResult,
        clientDetailsStatus
    }
}

export default connect(mapStateToProps, {})(NidaVerificationPage)