import React, { useEffect, useState } from "react";
import { Box, Typography, CardContent, Card, CardActions, Button, StepIndicator, Step, Stepper, stepClasses, stepIndicatorClasses, Chip, LinearProgress, Sheet, Table, Modal, ModalDialog, DialogTitle, ModalClose, DialogContent, FormControl, Input, CircularProgress, Divider, Link as LinkMoy } from "@mui/joy";
import { toast } from 'react-toastify';
import { LoadingView, PageTitle } from "../../../components";
import { NAVIGATE_TO_CLIENTLOANHISTORYPAGE, NAVIGATE_TO_CLIENTLOANREQUESTPAGE, NAVIGATE_TO_NIDAVERIFICATIONPAGE } from "../../../route/types";

import {
    activeLoanDetailRequest, activeLoanDetailReset,
    paymentMethodReset,
    initiatePushPaymentReset,
    pendingLoanDetailRequest, pendingLoanDetailReset,
    initiatePushPaymentRequest} from "../../../store/actions"
// import Card from '@mui/joy/Card';
import { CreditScore, HourglassBottomRounded, CheckCircleOutlineRounded, DescriptionOutlined, ExpandMore, ExpandLess, WarningRounded, PersonRounded } from "@mui/icons-material";
import { connect, useDispatch } from "react-redux";
import { FILE_BASE, OTHER_BASE, STATUS } from "../../../constant";
import { Link, useNavigate } from "react-router-dom";

import { formatDate, thousandSeparator } from "../../../utils";
import { useMediaQuery, } from "@mui/material";
import { useTheme } from '@mui/material/styles';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow'
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import { useTranslation } from "react-i18next";
import { t } from "i18next";



/* ---------------- LOANS CATEGORIES RENDER CONTENTS ----------------- */
const LoanCategories = ({ actionsProps, activeProps, noLoanProps, pendingProps, name }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    // let loanCategory = 'No_Loan'
    let loanCategory = actionsProps.loanStatus

    const schedules = activeProps.loanSchedule
    if (loanCategory == 'No_Loan') {
        if (noLoanProps.nidaStatus === 1) {
            navigate(NAVIGATE_TO_NIDAVERIFICATIONPAGE, { state: { checkNidaStatus: true } });
        }
        else {
            return (
                <Card
                    variant="soft"
                    sx={{
                        justifyContent: 'center',
                        // flexDirection: { xs: 'column', md: 'row' },
                        flexDirection: 'column',
                        columnGap: 6,
                        width: { xs: '100%', md: 'auto' },
                        alignItems: 'center',
                        px: { xs: 3, md: 20 },
                        py: { xs: 2, md: 8 },
                        background: 'linear-gradient(to bottom, #554E87, #0F0650)',
                        boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.3)'
                    }} >
                    <CardContent
                        sx={{
                            justifyContent: 'center',
                            alignItems: 'center'
                            // gap: 0
                        }}>
                        <Typography
                            level="body-lg"
                            sx={{
                                color: 'white'
                            }}>{t("home.limit")}</Typography>
                        <Typography
                            level="h2"
                            sx={{
                                color: 'white'
                            }}>Tsh. {thousandSeparator(noLoanProps.maximum_limit)}</Typography>
                        {/* <Chip
                            variant="outlined"
                            size="sm"
                            sx={{  backgroundColor: 'transparent', color: 'white' }}
                        >
                            Get fast loan
                        </Chip> */}
                    </CardContent>

                    <CardActions sx={{ flexDirection: 'column' }}>
                        {/* <Link to={NAVIGATE_TO_CLIENTLOANREQUESTPAGE, {
                            state: {
                                data: null,
                                EdStatus: false
                            }
                        }}> */}
                        <Button variant="soft" onClick={() => {
                            actionsProps.navigate(NAVIGATE_TO_CLIENTLOANREQUESTPAGE, {
                                state: {
                                    pending_data: {},
                                    EdStatus: false
                                }
                            })
                        }}
                            sx={{
                                backgroundColor: 'green',
                                color: 'white',
                                borderRadius: 20,
                                px: 5
                            }}>
                            {t("home.ombaMkopo")}
                        </Button>
                        {/* </Link> */}
                        <LinkMoy
                            href={NAVIGATE_TO_CLIENTLOANHISTORYPAGE}
                            underline="none"
                            sx={{
                                color: 'gold',
                                textDecorationLine: 'none',
                                fontWeight: 'bold',
                                fontSize: 14
                            }}>
                            {t("home.loanHistory")}
                        </LinkMoy>
                    </CardActions>
                </Card >
            )
        }
    }
    else if (loanCategory == 'Pending') {
        return (
            <Card
                variant="plain"
                sx={{
                    justifyContent: 'center',
                    flexDirection: 'column',
                    columnGap: 6,
                    alignItems: 'center',
                    px: { xs: 3, md: 10 },
                    py: { xs: 2, md: 8 },
                    width: { xs: '100%', md: 'auto' },
                    background: 'linear-gradient(to bottom, #554E87, #0F0650)',
                    boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.3)'
                }} >
                <CardContent
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        // gap: 0
                        width: '100%'
                    }}>
                    <Typography
                        level="body-lg"
                        sx={{
                            color: 'white',
                            textAlign: 'center'
                        }}>{t("penging.loan")}</Typography>

                    <Typography
                        level="h2"
                        sx={{
                            color: 'white',
                            textAlign: 'center',
                        }}>Tsh. {thousandSeparator(pendingProps.amount || 0)}</Typography>
                    <Chip
                        variant="soft"
                        size="sm"
                        startDecorator={<HourglassBottomRounded />}
                        sx={{ backgroundColor: 'gold', color: '#0f0650', alignSelf: 'center', margin: 0.2 }}
                    >
                        {t("pending.pending")} | {pendingProps.ambassador_status === 1 ? t("pending.statusCredit") : t("pending.statusAmb")}
                    </Chip>

                    {/* edit button */}
                    {/* {pendingProps.edit_status === 0 && <Button
                        color="success"
                        variant="soft"
                        startDecorator={<EditRounded />}
                        onClick={() => {
                            actionsProps.navigate(NAVIGATE_TO_CLIENTLOANREQUESTPAGE, {
                                state: {
                                    pending_data: pendingProps,
                                    EdStatus: true
                                }
                            })
                        }}>
                        EDIT
                    </Button>} */}
                    <Box
                        sx={{
                            width: '100%',
                            marginTop: { xs: 2, md: 4 },
                            gap: { xs: 0, md: 20 },
                            display: 'flex',
                            justifyContent: "space-between",
                        }}>
                        <Box>
                            <Typography
                                level="body-sm"
                                sx={{
                                    color: 'white'
                                }}><b>{t("pending.applicationDate")}:</b> <br />{formatDate(pendingProps.initiated_date)}</Typography>
                            <Typography
                                level="body-sm"
                                sx={{
                                    color: 'white'
                                }}><b>{t("pending.loanDuration")}:</b> <br />{pendingProps.cycle_count} {pendingProps.duration} {t("pending.months")}</Typography>
                        </Box>

                        <Box>
                            <Typography
                                level="body-sm"
                                sx={{
                                    color: 'white',
                                    textAlign: 'right'
                                }}><b>{t("pending.ambName")}:</b> <br />{pendingProps.ambassador_name}</Typography>
                            <Typography
                                level="body-sm"
                                sx={{
                                    color: 'white',
                                    textAlign: 'right'
                                }}><b>{t("pending.ambCode")}:</b> <br />{pendingProps.ambassador_code} </Typography>

                        </Box>
                    </Box>
                    <Button variant="solid" onClick={() => window.open(FILE_BASE + "/" + pendingProps.contract, "_blank")} sx={{ mt: 1 }}>
                        {t("pending.contract")}
                    </Button>

                </CardContent>
            </Card>
        )

    }
    else if (loanCategory == 'Active') {
        return (
            <Card
                variant="soft"
                sx={{
                    justifyContent: 'center',
                    flexDirection: 'column',
                    columnGap: 6,
                    alignItems: 'center',
                    width: { xs: '100%', md: 'auto' },
                    px: { xs: 3, md: 10 },
                    py: { xs: 2, md: 4 },
                    background: 'linear-gradient(to bottom, #554E87, #0F0650)',
                    boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.3)'
                }} >
                <CardContent
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        // gap: 0
                        width: '100%'
                    }}>
                    <Typography
                        level="body-lg"
                        sx={{
                            color: 'white',
                            textAlign: 'center',
                        }}>{t("active.loanRemain")}</Typography>

                    {/* remain loan amount */}
                    <Typography
                        level="h2"
                        sx={{
                            color: 'white',
                            textAlign: 'center'
                        }}>Tsh. {thousandSeparator(activeProps.remainDebt)}</Typography>

                    {/* progress status */}
                    <Box
                        sx={{
                            width: { xs: '90%', md: '90%' },
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignSelf: 'center'
                        }}>
                        <Typography
                            level="body-sm"
                            sx={{
                                color: 'white',
                            }}>{t("active.status")}</Typography>
                        <Typography
                            level="body-sm"
                            sx={{
                                color: 'white',
                            }}>{parseInt(activeProps.percent)} %</Typography>
                    </Box>
                    <LinearProgress
                        value={parseInt(activeProps.percent) || 0}
                        determinate
                        color="warning"
                        variant="solid"
                        sx={{
                            alignSelf: 'center',
                            width: { xs: '90%', md: '90%' },
                            '--LinearProgress-radius': '20px',
                            '--LinearProgress-thickness': '8px',
                            // '--LinearProgress-'
                        }} />

                    {/* loan details */}
                    <Box
                        sx={{
                            width: '100%',
                            marginTop: { xs: 2, md: 4 },
                            gap: { xs: 2, md: 45 },
                            display: 'flex',
                            justifyContent: "space-between",
                            alignItems: 'flex-start'
                        }}>
                        <Box>
                            <Typography
                                level="body-md"
                                sx={{
                                    color: 'white'
                                }}><b>{t("active.loanAmount")}:</b> <br />Tsh. {thousandSeparator(parseInt(activeProps.paidAmount) + parseInt(activeProps.remainDebt))}</Typography>
                            <Typography
                                level="body-md"
                                sx={{
                                    color: 'white'
                                }}><b>{t("active.amountPaid")}:</b> <br />Tsh. {thousandSeparator(activeProps.paidAmount)}</Typography>
                            <Typography
                                level="body-md"
                                sx={{
                                    color: 'white'
                                }}><b>{t("active.deadline")}: </b><br />{activeProps.deadlineDate}</Typography>
                        </Box>

                        <Box>
                            <Typography
                                level="body-md"
                                sx={{
                                    color: 'white'
                                }}><b>{t("active.loanDuration")}:</b> <br />{activeProps.duration} Months</Typography>
                            <Typography
                                level="body-md"
                                sx={{
                                    color: 'white'
                                }}><b>{t("active.approveDate")}:</b> <br />{activeProps.formattedApprovedDate}</Typography>
                        </Box>
                    </Box>
                </CardContent>
                <CardActions sx={{
                    // alignSelf: 'center',
                    width: '100%',
                    display: 'flex',
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: { xs: 'center', md: 'space-between' }
                }}>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                            // justifyContent: 'space-between',
                        }}>
                        <Button variant="soft" size="sm" onClick={actionsProps.setOpenSchedule} startDecorator={actionsProps.openSchedule ? <ExpandLess /> : <ExpandMore />} sx={{ mt: 1 }}>
                            {t("active.repaySchedule")}
                        </Button>
                        <Link to={FILE_BASE + "/" + activeProps.contract} target="_blank" rel="noopener noreferrer">
                            <Button variant="soft" size="sm" startDecorator={<DescriptionOutlined />} sx={{ mt: 1 }}>
                                {t("active.contract")}
                            </Button>
                        </Link>
                    </Box>

                    <Button
                        variant="solid"
                        onClick={actionsProps.openPushModal}
                        size="md"
                        sx={{
                            mt: 1,
                            width: { xs: '100%', md: 'auto' },
                            backgroundColor: 'green',
                            color: 'white',
                            borderRadius: 20,
                            px: 5,
                            py: { xs: 1.2 },
                            fontSize: 16,
                        }}>
                        {t("active.push")}
                    </Button>
                </CardActions>

                {actionsProps.openSchedule && loanSchedules(schedules)}
            </Card>
        )

    }
    else {
        return (
            <Card sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                top: '20%'
            }}>
                <WarningRounded sx={{ fontSize: '50px', color: 'background.appcolor' }} />
                <Typography level="title-md">{t("active.paymentData")}</Typography>
                <Typography level="body-sm" textAlign={'center'}>{t("alert.network")}</Typography>
            </Card>
        )
    }

}
// STEPS TO GET LOAN
const renderSteps = (isMobile) => {

    const item = [{
        id: 1,
        icon: CreditScore,
        title: t("home.apply")
    },
    {
        id: 2,
        icon: PersonRounded,
        title: t("home.ambassadorApproval"),
    },
    {
        id: 3,
        icon: HourglassBottomRounded,
        title: t("home.managerApproval"),
    },
    {
        id: 4,
        icon: CheckCircleOutlineRounded,
        title: t("home.receiveLoan")
    }]

    return (
        <Box sx={{
            pt: 1,
            width: { xs: '100%', md: 'auto' }
        }}>
            <Typography level="title-md" sx={{ color: { md: 'whitesmoke' }, fontWeight: 'bold' }}>Steps to Get Loan</Typography>
            <Stepper
                size="lg"
                orientation={isMobile ? 'horizontal' : 'vertical'}
                sx={{
                    width: '100%',
                    pt: 1,
                    '--StepIndicator-size': '3rem',
                    '--Stepper-verticalGap': '2.5rem',
                    // '--StepIndicator-size': '2.5rem',
                    // '--Step-gap': '1rem',
                    '--Step-connectorInset': '0.5rem',
                    '--Step-connectorRadius': '1rem',
                    '--Step-connectorThickness': '2px',
                    // '--joy-palette-success-solidBg': 'var(--joy-palette-success-400)',
                    [`& .${stepIndicatorClasses.root}`]: {
                        borderWidth: 4,
                        // '&::after': { bgcolor: 'white' },
                    },
                    [`& .${stepClasses.root}::after`]: {
                        // height: 14,
                        backgroundColor: { xs: "text.secondary", md: 'white' },
                    },
                    [`& .${stepClasses.active}`]: {
                        [`& .${stepIndicatorClasses.root}`]: {
                            borderColor: 'currentColor',
                        },
                    },
                }}>
                {item.map((item) => (
                    <Step
                        key={item.id}
                        orientation={isMobile ? 'vertical' : 'horizontal'}
                        // orientation="vertical"
                        active
                        indicator={
                            <StepIndicator variant="soft">
                                <item.icon />
                            </StepIndicator>
                        }
                    >
                        <Typography
                            sx={{
                                // textTransform: 'uppercase',
                                fontWeight: 'lg',
                                fontSize: '0.75rem',
                                letterSpacing: '0.5px',
                                textAlign: { xs: 'center', md: "left" },
                                color: { md: 'whitesmoke' }
                            }}
                        >
                            {item.title}
                        </Typography>
                    </Step>
                ))}
            </Stepper >
        </Box>
    )
}


// LOAN SCHEDULES
const loanSchedules = (loanSchedules) => {

    const status = (st) => {
        if (st == 3 || st == 4) {
            return t("schedule.paid")
        }
        else if (st == 5 || st == 6 || st == 7) {
            return t("schedule.delay")
        } else {
            return t("schedule.active")
        }
    }
    return (
        <Sheet
            variant="soft"
            sx={{
                p: 1,
                pl: 6,
                boxShadow: "inset 0 3px 6px 0 rgba(0 0 0 / 0.08)",
            }}>
            <Typography level="body-lg" component="div"></Typography>
            <Table
                borderAxis="bothBetween"
                size="sm"
                aria-label="purcha"
                sx={{
                    "& > thead > tr > th:nth-child(n + 3), & > tbody > tr > td:nth-child(n + 3)":
                        {},
                    "--TableCell-paddingX": "0.5rem",
                }}>
                <thead>
                    <tr>
                        <th>{t("schedule.dueDate")}</th>
                        <th>{t("schedule.amount")}</th>
                        <th>{t("schedule.remain")}</th>
                        <th>{t("schedule.status")}</th>
                    </tr>
                </thead>
                <tbody>
                    {loanSchedules.map((sch) => (
                        <tr key={sch.id}>
                            <td>{formatDate(sch.due_date)}</td>
                            <td>{sch.installment_amount}</td>
                            <td>{sch.remain}</td>
                            <td>{status(sch.status)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Sheet>
    )
}

/* ------------------ MAIN FUNCTION -------------------- */
const HomeClient = ({
    loginResult,
    loanHistoryResult,
    loanHistoryStatus,
    loanHistoryErrorMessage,

    clientDetailsStatus,
    clientDetailsResult,
    clientDetailsErrorMessage,

    pendingLoanDetailStatus,
    pendingLoanDetailResult,
    pendingLoanDetailErrorMessage,

    loanDetailRequestStatus,
    loanDetailResult,
    loanDetailErrorMessage,
    paymentMethodResult,
    paymentMethodStatus,
    paymentMethodErrorMessage,
    initiatePushPaymentStatus,
    initiatePushPaymentResult,
    initiatePushPaymentErrorMessage,
    transactionHistoryRequestStatus,
    transactionHistoryRequestResult,
}) => {

    const [openSchedule, setOpenSchedule] = useState(false)


    const dispatch = useDispatch();
    const navigate = useNavigate()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // for active
    const [debt, setDept] = useState(0);
    const [remainDebt, setRemainDebt] = useState(0);
    const [duration, setDuration] = useState(0);
    const [approvedDate, setApprovedDate] = useState('');
    const [formattedApprovedDate, setFormatedApprovedDate] = useState('');
    const [deadlineDate, setDeadlineDate] = useState('');
    const [paidAmount, setPaidAmount] = useState(0);
    const [percent, setPercent] = useState(0);
    const [isLate, setIsLate] = useState(false);
    const [loanSchedule, setLoanSchedule] = useState([]);
    const [contract, setContract] = useState("")
    const [nidaStatus, setNidaStatus] = useState("");

    // for pending
    const [pendingData, setPendigData] = useState({})

    // for client details and status
    const [name, setName] = useState('');
    const [loanStatus, setLoanStatus] = useState("");

    const activeProps = {
        debt: debt,
        remainDebt: remainDebt,
        duration: duration,
        approvedDate: approvedDate,
        formattedApprovedDate: formattedApprovedDate,
        deadlineDate: deadlineDate,
        paidAmount: paidAmount,
        percent: percent,
        isLate: isLate,
        loanSchedule: loanSchedule,
        contract: contract
    }

    useEffect(() => {
        // client details
        if (clientDetailsStatus === STATUS.SUCCESS) {
            setLoanStatus(clientDetailsResult.loan_status)
            setNidaStatus(clientDetailsResult.nida_status);
            if (clientDetailsResult.loan_status === "Active") {
                dispatch(activeLoanDetailRequest(loginResult.token));
            } else if (clientDetailsResult.loan_status === "Pending") {
                dispatch(pendingLoanDetailRequest(loginResult.token));
            }
        }
        else if (clientDetailsStatus === STATUS.ERROR) {
            // dispatch(clientDetailsReset())
            toast.error(clientDetailsErrorMessage)
        }
    }, [clientDetailsStatus])

    useEffect(() => {
        // active loans
        if (loanDetailRequestStatus === STATUS.SUCCESS) {
            // console.log("Fetched loan Data", loanDetailResult)
            setDuration(loanDetailResult.duration);
            setApprovedDate(loanDetailResult.approved_date);
            setLoanSchedule(loanDetailResult.repayment_schedule);
            setContract(loanDetailResult.contract);

            if (parseInt(loanDetailResult.amount)) {
                setDept(parseInt(loanDetailResult.amount));
            }

            if (parseInt(loanDetailResult.paid_amount)) {
                setPaidAmount(parseInt(loanDetailResult.paid_amount));
            }

            if (loanDetailResult.remain_amount) {
                setRemainDebt(parseInt(loanDetailResult.remain_amount))
            }


            dispatch(activeLoanDetailReset());
        }
        else if (loanDetailRequestStatus == STATUS.ERROR) {
            // toast.error(loanDetailErrorMessage)
            dispatch(activeLoanDetailReset());
        }

        // payment methode
        if (paymentMethodStatus === STATUS.SUCCESS) {
            if (paymentMethodResult) {
                // navigate(NAVIGATION_PAYMENT_METHOD_SCREEN, {
                //     support_list: paymentMethodResult.content
                // });
            } else {
                toast.error('Failed to fetch payment data')
            }

            dispatch(paymentMethodReset());
        }
        else if (paymentMethodStatus === STATUS.ERROR) {
            toast.error(paymentMethodErrorMessage)
            dispatch(paymentMethodReset());
        }

        // pending loans
        if (pendingLoanDetailStatus === STATUS.SUCCESS) {
            if (pendingLoanDetailResult) {
                console.log("PENDING DATA: ", pendingLoanDetailResult)
                setPendigData(pendingLoanDetailResult)
                dispatch(pendingLoanDetailReset())
            }
        }
        else if (pendingLoanDetailStatus === STATUS.ERROR) {
            // toast.error(pendingLoanDetailErrorMessage)
            dispatch(pendingLoanDetailReset())
        }

        // payment push
        if (initiatePushPaymentStatus === STATUS.SUCCESS) {
            setLoadPush(false)
            setPushModal(false)
            toast.success(t("push.success",{mobile: loginResult.mobile}))
            dispatch(initiatePushPaymentReset());
        }
        else if (initiatePushPaymentStatus === STATUS.ERROR) {
            setLoadPush(false)
            toast.success(initiatePushPaymentErrorMessage)
            dispatch(initiatePushPaymentReset());
        }

    });

    // calculate debt percentage
    useEffect(() => {
        //calculate percentage
        var _totalLoan = debt + remainDebt;

        var _paidAmount = paidAmount ? paidAmount : 0

        var _paidPercent = (_paidAmount / _totalLoan) * 100;
        if (_paidPercent > 100) {
            setPercent(100);
        }
        setPercent(_paidPercent);

    }, [paidAmount, debt]);

    // set and check deadline date
    useEffect(() => {
        if (approvedDate != '') {
            //as all current duration is month so we only add month
            var app_date = new Date(approvedDate);
            //format approve Date
            setFormatedApprovedDate(app_date.getDate() + "/" + (app_date.getMonth() + 1) + "/" + app_date.getFullYear());
            let _end_date = new Date(app_date);
            _end_date.setMonth(_end_date.getMonth() + duration);
            var deadline = _end_date.getDate() + "/" + (_end_date.getMonth() + 1) + "/" + _end_date.getFullYear();

            setDeadlineDate(deadline);

            var _today = new Date();
            if (_today > _end_date) {
                setIsLate(true)
                //console.log("Late loan")
            } else {
                setIsLate(false);
                //console.log("loan is not due yet")
            }
        }
    }, [approvedDate])

    // to set name
    useEffect(() => {
        const [first, last] = loginResult.name.split(' ');
        setName(first)
    }, [name])

    const goToLoanRequestPage = () => {
        navigate(NAVIGATE_TO_CLIENTLOANREQUESTPAGE)
    }

    // for pushmodal
    const [pushModal, setPushModal] = useState(false)
    const [inputAmount, setInputAmount] = useState("");
    const [wholeAmount, setWholeAmount] = useState(false)
    const [loadPush, setLoadPush] = useState(false)

    useEffect(() => {
        if (wholeAmount) {
            setInputAmount(remainDebt.toString())
        } else {
            setInputAmount(inputAmount)
        }
    }, [wholeAmount])

    const startPush = () => {
        if (inputAmount) {
            setLoadPush(true)
            dispatch(initiatePushPaymentRequest(loginResult.token, inputAmount))
        } else {
            toast.error(t("push.insertAmount"))
        }
    }

    // check loading status
    const checkLoading = () => {
        if (clientDetailsStatus === STATUS.LOADING) {
            return true
        }
        else if (loanStatus === 'Active' && loanDetailRequestStatus === STATUS.LOADING) {
            return true
        }
        else if (loanStatus === "Pending" && pendingLoanDetailStatus === STATUS.LOADING) {
            return true

        } else {
            return false
        }
    }

    return (
        <Box>
            <PageTitle title={t("home.welcome") + ", " + name} />
            <LoadingView loading={checkLoading()} />
            {/* <ToastContainer /> */}
            <Box sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                justifyContent: 'center',
                alignItems: 'center',
                gap: { xs: 2, md: 5 },
            }}>
                <LoanCategories
                    actionsProps={{
                        openSchedule: openSchedule,
                        setOpenSchedule: () => setOpenSchedule(!openSchedule),
                        goToLoanRequestPage: goToLoanRequestPage,
                        openPushModal: () => setPushModal(true),
                        navigate: navigate,
                        loanStatus: loanStatus
                    }}
                    activeProps={activeProps}
                    noLoanProps={{
                        maximum_limit: loginResult.maximum_limit,
                        nidaStatus
                    }}
                    pendingProps={pendingData}
                    name={name}
                />

                <Divider orientation="vertical" sx={{ display: { xs: 'none', md: 'flex' }, backgroundColor: 'white', width: 2, borderRadius: 10 }} />
                {renderSteps(isMobile)}
            </Box>

            {/* PUSH MODAL */}
            <Modal open={pushModal} >
                <ModalDialog
                    aria-labelledby="nested-modal-title"
                    aria-describedby="nested-modal-description"
                    sx={(theme) => ({
                        [theme.breakpoints.only('xs')]: {  // ----------------> FOR MOBILE
                            top: 'unset',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            // borderRadius: 0,
                            borderTopLeftRadius: 20,
                            borderTopRightRadius: 20,
                            transform: 'none',
                            maxWidth: 'unset',
                        },
                    })}>
                    <ModalClose variant="outlined" component="button" onClick={() => setPushModal(false)} />
                    <DialogTitle>{t("push.title")}</DialogTitle>
                    <DialogContent>{t("push.desc")}</DialogContent>
                    <form
                    // onSubmit={startPush}
                    >
                        <FormControl sx={{ pb: 1 }}>
                            {/* <FormLabel></FormLabel> */}
                            <Button variant="plain" onClick={() => setWholeAmount(true)}>{t("push.payWhole")}</Button>
                            <Input
                                autoFocus
                                type="number"
                                required
                                value={inputAmount}
                                defaultValue={inputAmount}
                                onChange={(event) => setInputAmount(event.target.value)}
                            />
                        </FormControl>

                        <Button
                            startDecorator={loadPush && <CircularProgress />}
                            // type="submit"
                            onClick={startPush}
                            fullWidth>
                            {loadPush ? t("push.loading") : t("push.proceed")}
                        </Button>
                    </form>
                </ModalDialog>
            </Modal>

            <AdvertisementSlideshow adv={loginResult.advertisements} />
        </Box>
    )
}

// Advertiesment slides
const AdvertisementSlideshow = ({ adv }) => {
    // const swiper = useSwiperSlide();

    const advertisements = adv

    // const advertisements = [
    //     { id: 1, image: image.Images.backgroung, title: 'Ad 1' },
    //     { id: 2, image: image.Images.backgroung2, title: 'Ad 2' },
    //     { id: 3, image: image.Images.backgroung, title: 'Ad 3' },
    //     { id: 4, image: image.Images.backgroung2, title: 'Ad 4' },
    //     { id: 5, image: image.Images.backgroung, title: 'Ad 5' },
    //     { id: 6, image: image.Images.backgroung2, title: 'Ad 6' },
    // ];

    return (
        <Box
            sx={{
                width: '100%',
                maxWidth: '1000px',
                margin: 'auto',
                marginTop: { xs: 2, md: 5 },
                overflow: 'hidden',
                padding: 1
            }}
        >
            <Swiper
                modules={[Autoplay]}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                centeredSlides={true}
                pagination={{ clickable: true }}
                loop={true}
                breakpoints={{
                    // Adjust slidesPerView for responsive design
                    640: { slidesPerView: 1, spaceBetween: 10 }, // Small screens
                    1024: { slidesPerView: 2, spaceBetween: -20 }, // Medium screens
                    1200: { slidesPerView: 3, spaceBetween: -20 }, // Large screens
                }}
            >
                {advertisements.map((ad, index) => (
                    <SwiperSlide key={index}>
                        {({ isActive }) => (
                            <Box
                                component="img"
                                src={OTHER_BASE + "/" + ad}
                                alt={ad}
                                sx={(theme) => ({
                                    width: '100%',
                                    height: 'auto',
                                    borderRadius: 'md',
                                    boxShadow: isActive ? '0px 4px 20px rgba(0, 0, 0, 0.3)' : 'none',
                                    transition: 'transform 0.5s ease', // Smooth scaling
                                    transform: isActive ? 'scale(1)' : 'scale(0.8)', // Scale active and inactive slides
                                    opacity: isActive ? 1 : 0.5, // Highlight active slide
                                })}
                            />)}
                    </SwiperSlide>
                ))}
            </Swiper>
        </Box>
    );
};


const mapStateToProps = ({ authClient, account, loan }) => {
    const {
        otpResult: loginResult,
    } = authClient
    const {
        requestPaymentMethodResult: paymentMethodResult,
        requestPaymentMethodStatus: paymentMethodStatus,
        requestPaymentMethodErrorMessage: paymentMethodErrorMessage,


        transactionHistoryRequestResult: transactionHistoryRequestResult,
        transactionHistoryRequestStatus: transactionHistoryRequestStatus,

        clientDetailsStatus: clientDetailsStatus,
        clientDetailsResult: clientDetailsResult,
        clientDetailsErrorMessage: clientDetailsErrorMessage,
    } = account
    const {
        loanHistoryRequestStatus: loanHistoryStatus,
        loanHistoryRequestErrorMessage: loanHistoryErrorMessage,
        loanHistoryRequestResult: loanHistoryResult,

        pendingLoanDetailStatus: pendingLoanDetailStatus,
        pendingLoanDetailResult: pendingLoanDetailResult,
        pendingLoanDetailErrorMessage: pendingLoanDetailErrorMessage,

        activeLoanDetailStatus: loanDetailRequestStatus,
        activeLoanDetailResult: loanDetailResult,
        activeLoanDetailErrorMessage: loanDetailErrorMessage,

        initiatePushPaymentStatus: initiatePushPaymentStatus,
        initiatePushPaymentResult: initiatePushPaymentResult,
        initiatePushPaymentErrorMessage: initiatePushPaymentErrorMessage
    } = loan

    return {
        loginResult,
        loanHistoryResult,
        loanHistoryStatus,
        loanHistoryErrorMessage,

        pendingLoanDetailStatus,
        pendingLoanDetailResult,
        pendingLoanDetailErrorMessage,

        clientDetailsStatus,
        clientDetailsResult,
        clientDetailsErrorMessage,

        loanDetailRequestStatus,
        loanDetailResult,
        loanDetailErrorMessage,
        paymentMethodResult,
        paymentMethodStatus,
        paymentMethodErrorMessage,
        initiatePushPaymentStatus,
        initiatePushPaymentResult,
        initiatePushPaymentErrorMessage,
        transactionHistoryRequestStatus,
        transactionHistoryRequestResult,
    }
}
export default connect(mapStateToProps, {})(HomeClient)