import React from "react";
import { Box, Typography } from "@mui/joy";
import { PageTitle } from "../../../components";

const RepaymentPlanPage = () => {

    // useEffect(() => {
    //     toast.success('Welcome To Sava')
    // }, [])
    return (
        <Box>
        <PageTitle title={'Repayment Plan'}/>
            {/* <Typography variant="div"> */}
            {/* <ToastContainer /> */}
            <Typography>
                Loan Request page here
            </Typography>
            {/* </Typography> */}
        </Box>
    )
}

export default RepaymentPlanPage