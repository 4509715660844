import { FORGET_PASSWORD_URL, LOGIN_CLIENT_URL as LOGIN_URL, TERMS_AND_CONDITION_URL } from "../../constant"
import { guestRequest } from "../calls"
// import VersionCheck from "react-native-version-check";

function doGuestLogin(username, password, fcm_token) {
    // let app_version = VersionCheck.getCurrentVersion();
    let app_version = "1.1.10"
    const data = {
        username,
        password,
        'version': app_version,
        'push_device_token': fcm_token
    }
    return guestRequest(LOGIN_URL,data );
}

function doForgetPassword(nida, mobile) {
    const data = {
        nida,
        mobile
    }
    return guestRequest(FORGET_PASSWORD_URL, data);
}

function doFetchTermsAndCondition(category, language) {
    const data = {
        category,
        language
    }

    return guestRequest(TERMS_AND_CONDITION_URL, data);
}


export {
    doGuestLogin,
    doFetchTermsAndCondition,
    doForgetPassword
}