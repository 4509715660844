import React, { useEffect, useState } from "react";
import { Box, Chip, ColorPaletteProp, Dropdown, IconButton, List, ListDivider, ListItem, ListItemContent, ListItemDecorator, Menu, MenuButton, MenuItem, Table, Typography, Sheet, Input, iconButtonClasses, Button, Card, } from "@mui/joy";
import { toast } from 'react-toastify';
import { LoadingView, PageTitle } from "../../../components";
import { STATUS } from "../../../constant";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";


import SearchIcon from '@mui/icons-material/Search';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import BlockIcon from '@mui/icons-material/Block';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import {
  
  loanHistoryReset
  } from "../../../store/actions"
import { formatDate, thousandSeparator } from "../../../utils";
import { WarningRounded } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const MobileViewTable = ({ loanHistory }) => {
  const {t} =  useTranslation();

  return (
    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
      {loanHistory.map((listItem) => {
        const _month = new Date(listItem.initiated_date).toLocaleString('en-us', { month: 'short', year: 'numeric' });
        const _day = new Date(listItem.initiated_date).toLocaleString('en-us', { day: '2-digit' });
        return (
          <List
            key={listItem.id}
            size="sm"
            sx={{
              '--ListItem-paddingX': 0,
            }}
          >
            <ListItem
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start',
              }}
            >
              <ListItemContent sx={{ display: 'flex', gap: 2, alignItems: 'start' }}>
                <ListItemDecorator>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      p: 1,
                      backgroundColor: {
                        1: "gray",
                        4: "green",
                        5: "red"
                      }[listItem.loan_status]
                    }}>
                    <Typography level="title-lg" sx={{ color: 'white', fontSize: 24 }}>{_day}</Typography>
                    <Typography sx={{ color: 'white', fontSize: 12 }}>{_month}</Typography>
                  </Box>
                </ListItemDecorator>
                <div>
                  <Typography fontWeight={600}> Tsh. {thousandSeparator(listItem.principle_amount)}</Typography>
                  <Typography level="body-xs"><b>{t("loanHistory.ref")}:</b> {listItem.loan_reference}</Typography>
                  <Typography level="body-xs"><b>{t("loanHistory.loanTenure")}:</b> {listItem.cycle_count + " " + listItem.duration}</Typography>
                  <Dropdown>
                    <MenuButton variant="plain" size="sm" sx={{ p: 0, m: 0 }}><Typography level="title-sm">View more...</Typography></MenuButton>
                    <Menu size="sm">
                      {listItem.loan_status === "4" ?
                        <MenuItem><b>{t("loanHistory.approve_date")}:</b> {listItem.approved_date}</MenuItem>
                        : <>
                          <MenuItem><b>{t("loanHistory.decline_reason")}:</b> {listItem.decline_reason}</MenuItem>
                          <MenuItem><b>{t("loanHistory.decline_date")}:</b> {listItem.decline_date}</MenuItem>
                        </>}
                    </Menu>
                  </Dropdown>
                </div>
              </ListItemContent>
              <Chip
                variant="soft"
                size="sm"
                startDecorator={
                  {
                    1: <AutorenewRoundedIcon />,
                    4: <CheckRoundedIcon />,
                    5: <BlockIcon />,
                  }[listItem.loan_status]
                }
                color={
                  {
                    1: "neutral",
                    4: 'success',
                    5: 'danger',
                  }[listItem.loan_status] as ColorPaletteProp
                }
              >
                {{
                  1: t("loanHistory.status.pending"),
                  4: t("loanHistory.status.paid"),
                  5: t("loanHistory.status.decline")
                }[listItem.loan_status]}
              </Chip>
            </ListItem>
            <ListDivider />
          </List>
        )
      })}
    </Box>
  )
}

const DesktopViewTable = ({ loanHistory }) => {
  const {t} = useTranslation();
  return (
    <React.Fragment>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: 'none', md: 'flex' },
          // width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '--Table-headerUnderlineThickness': '1px',
            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
            '--TableCell-paddingY': '4px',
            '--TableCell-paddingX': '8px',
          }}
        >
          <thead>
            <tr>
              <th style={{ width: 140, padding: '12px 6px' }}>{t("loanHistory.date")}</th>
              <th style={{ width: 140, padding: '12px 6px' }}>{t("loanHistory.amount")}</th>
              <th style={{ width: 140, padding: '12px 6px' }}>{t("loanHistory.loanDuration")}</th>
              <th style={{ width: 140, padding: '12px 6px' }}>{t("loanHistory.ref")}</th>
              <th style={{ width: 100, padding: '12px 6px' }}>{t("loanHistory.status.title")}</th>
              <th style={{ width: 140, padding: '12px 6px' }}>{t("loanHistory.action")}</th>
            </tr>
          </thead>
          <tbody>
            {loanHistory.map((row) => (
              <tr key={row.approved_data}>
                <td>
                  <Typography level="body-xs">{formatDate(row.initiated_date)}</Typography>
                </td>
                <td>
                  <Typography level="body-xs">Tsh. {thousandSeparator(row.principle_amount)}</Typography>
                </td>
                <td>
                  <Typography level="body-xs">{row.cycle_count + " " + row.duration}</Typography>
                </td>
                <td>
                  <Typography level="body-xs">{row.loan_reference}</Typography>
                </td>
                <td>
                  <Chip
                    variant="outlined"
                    size="sm"
                    startDecorator={
                      {
                        1: <AutorenewRoundedIcon />,
                        4: <CheckRoundedIcon />,
                        5: <BlockIcon />,
                      }[row.loan_status]
                    }
                    color={
                      {
                        1: "neutral",
                        4: 'success',
                        5: 'danger',
                      }[row.loan_status] as ColorPaletteProp
                    }
                  >
                    {{
                      1: t("loanHistory.status.pending"),
                      4: t("loanHistory.status.paid"),
                      5: t("loanHistory.status.decline")
                    }[row.loan_status]}
                  </Chip>
                </td>
                <td>
                  <Dropdown>
                    <MenuButton size="sm" ><Typography level="title-sm">view more</Typography></MenuButton>
                    <Menu size="sm">
                      {row.loan_status === "4" ?
                        <MenuItem><b>{t("loanHistory.approve_date")}:</b> {row.approved_date}</MenuItem>
                        : <>
                          <MenuItem><b>{t("loanHistory.decline_reason")}:</b> {row.decline_reason}</MenuItem>
                          <MenuItem><b>{t("loanHistory.decline_date")}:</b> {row.decline_date}</MenuItem>
                        </>}
                    </Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Sheet>
    </React.Fragment>
  );
}

const ClientLoanHistoryPage = ({
  loginResult,

  loanHistoryStatus,
  loanHistoryResult,
  loanHistoryErrorMessage,

  clientDetailsStatus,
  clientDetailsResult,
  clientDetailsErrorMessage
}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isDesktop = useMediaQuery("(min-width:600px)");

  const [loanStatus, setLoanStatus] = useState('')
  const [loanHistory, setLoanHistory] = useState([])

  useEffect(() => {
    // client details
    if (clientDetailsStatus === STATUS.SUCCESS) {
      setLoanStatus(clientDetailsResult.loan_status)
      // if (clientDetailsResult.loan_status === "Active") {
      //     dispatch(activeLoanDetailRequest(loginResult.token));
      // } else if (clientDetailsResult.loan_status === "Pending") {
      //     dispatch(pendingLoanDetailRequest(loginResult.token));
      // }
    }
    else if (clientDetailsStatus === STATUS.ERROR) {
      // dispatch(clientDetailsReset())
      toast.error(clientDetailsErrorMessage)
    }
  }, [clientDetailsStatus])

  useEffect(() => {
    // loan history
    if (loanHistoryStatus === STATUS.SUCCESS) {
      // console.log("HISTORY: ", loanHistoryResult)
      setLoanHistory(loanHistoryResult.history)
      // dispatch(loanHistoryReset());
    }
    else if (loanHistoryStatus === STATUS.ERROR) {
      // toast.error(loanHistoryErrorMessage)
      dispatch(loanHistoryReset())
    }
  }, [loanHistoryStatus])


  const [currentPage, setCurrentPage] = useState(0);
  const ITEMS_PER_PAGE = 20;

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(0, prevPage - 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(loanHistory.length / ITEMS_PER_PAGE) - 1));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedData = loanHistory.slice(currentPage * ITEMS_PER_PAGE, (currentPage + 1) * ITEMS_PER_PAGE);

  const checkLoading = () => {
    if (loanHistoryStatus === STATUS.LOADING) {
      return true
    } else {
      return false
    }
  }

  return (
    <Box>
      <PageTitle title={t("loanHistory.title")} />

      <LoadingView loading={checkLoading()} />

      {loanHistory.length > 0 ? <>

        {/* search */}
        <Sheet
          className="SearchAndFilters"
          sx={{
            display: 'flex',
            my: 1,
            gap: 1,
          }}
        >
          <Input
            size="sm"
            placeholder="Search"
            startDecorator={<SearchIcon />}
            sx={{ flexGrow: 1 }}
          />
        </Sheet>
        <MobileViewTable loanHistory={paginatedData} />
        <DesktopViewTable loanHistory={paginatedData} />

        {/* Pagination */}
        {loanHistory.length > ITEMS_PER_PAGE
          &&
          <Box
            className="Pagination-laptopUp"
            sx={{
              pt: 2,
              gap: 1,
              [`& .${iconButtonClasses.root}`]: { borderRadius: '50%' },
              display: 'flex'
            }}
          >
            <Button
              size="sm"
              variant="outlined"
              color="neutral"
              startDecorator={<KeyboardArrowLeftIcon />}
              onClick={handlePreviousPage}
              disabled={currentPage === 0}
            >
              {isDesktop ? 'Previous' : ""}
            </Button>


            <Box sx={{ flex: 1 }} />
            {/* for desktop to display page number */}
            {Array.from({ length: Math.ceil(loanHistory.length / ITEMS_PER_PAGE) }).map((_, page) => (
              <IconButton
                key={page}
                size="sm"
                variant={'outlined'}
                color="neutral"
                onClick={() => handlePageChange(page)}
                disabled={currentPage === page}
                sx={{ display: { xs: 'none', md: 'flex' } }}
              >
                {page + 1}
              </IconButton>
            ))}

            {/* for mobile to display page number */}
            <Typography level="body-sm" mx="auto" textAlign={'center'} sx={{ display: { xs: 'flex', md: 'none' } }}>
              Page {currentPage + 1} of {Math.ceil(loanHistory.length / ITEMS_PER_PAGE)}
            </Typography>
            <Box sx={{ flex: 1 }} />



            <Button
              size="sm"
              variant="outlined"
              color="neutral"
              endDecorator={<KeyboardArrowRightIcon />}
              onClick={handleNextPage}
              disabled={currentPage === Math.ceil(loanHistory.length / ITEMS_PER_PAGE) - 1}
            >
              {isDesktop ? 'Next' : ""}
            </Button>
          </Box>}

      </> :
        <Card sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          top: '20%'
        }}>
          <WarningRounded sx={{ fontSize: '50px', color: 'background.appcolor' }} />
          <Typography level="title-md">{t("loanHistory.notFoundTitle")}</Typography>
          <Typography level="body-sm" textAlign={'center'}>{t("loanHistory.notFoundDesc")}</Typography>
        </Card>
      }
    </Box>
  )
}

const mapStateToProps = ({ authClient, loan, account }) => {
  const {
    otpResult: loginResult
  } = authClient
  const {
    loanHistoryRequestStatus: loanHistoryStatus,
    loanHistoryRequestErrorMessage: loanHistoryErrorMessage,
    loanHistoryRequestResult: loanHistoryResult,


  } = loan
  const {
    clientDetailsStatus: clientDetailsStatus,
    clientDetailsResult: clientDetailsResult,
    clientDetailsErrorMessage: clientDetailsErrorMessage
  } = account

  return {
    loginResult,

    loanHistoryStatus,
    loanHistoryResult,
    loanHistoryErrorMessage,

    clientDetailsStatus,
    clientDetailsResult,
    clientDetailsErrorMessage
  }
}

export default connect(mapStateToProps, {})(ClientLoanHistoryPage)