import { CLIENT, STATUS } from "../../constant";

const INITIAL_STATE = {
    ambassadorValidateStatus: STATUS.DEFAULT,
    ambassadorValidateErrorMessage: "",
    ambassadorResult: null,

    loanRequestStatus: STATUS.DEFAULT,
    loanRequestErrorMessage: "",
    loanRequestResult: null,

    editLoanRequestStatus: STATUS.DEFAULT,
    editLoanRequestErrorMessage: "",
    editLoanRequestResult: null,

    loanHistoryRequestStatus: STATUS.DEFAULT,
    loanHistoryRequestErrorMessage: "",
    loanHistoryRequestResult: [],

    pendingLoanDetailStatus: STATUS.DEFAULT,
    pendingLoanDetailResult: null,
    pendingLoanDetailErrorMessage: "",

    underWritingStatus: STATUS.DEFAULT,
    underWritingResult: null,
    underWritingErrorMessage: "",

    editUnderWritingStatus: STATUS.DEFAULT,
    editUnderWritingResult: null,
    editUnderWritingErrorMessage: "",

    activeLoanDetailStatus: STATUS.DEFAULT,
    activeLoanDetailResult: null,
    activeLoanDetailErrorMessage: "",

    initiatePushPaymentStatus: STATUS.DEFAULT,
    initiatePushPaymentResult: null,
    initiatePushPaymentErrorMessage: "",

    nidaStatus: STATUS.DEFAULT,
    nidaResult: null,
    nidaErrorMessage: '',

    referenceStatus: STATUS.DEFAULT,
    referenceResult: null,
    referenceErrorMessage: ''
}

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case CLIENT.AMBASSADOR_VALIDATE_LOADING:
            return {
                ...state,
                ambassadorValidateStatus: STATUS.LOADING,
                ambassadorValidateErrorMessage: "",
                ambassadorResult: null
            }
        case CLIENT.AMBASSADOR_VALIDATE_SUCCESS:
            return {
                ...state,
                ambassadorValidateStatus: STATUS.SUCCESS,
                ambassadorValidateErrorMessage: "",
                ambassadorResult: payload
            }
        case CLIENT.AMBASSADOR_VALIDATE_FAILURE:
            return {
                ...state,
                ambassadorValidateStatus: STATUS.ERROR,
                ambassadorValidateErrorMessage: payload,
                ambassadorResult: null
            }
        case CLIENT.AMBASSADOR_VALIDATE_RESET:
            return {
                ...state,
                ambassadorValidateStatus: STATUS.DEFAULT,
                ambassadorValidateErrorMessage: "",
                ambassadorResult: null
            }

        case CLIENT.UNDERWRITING_LOADING:
            return {
                ...state,
                underWritingStatus: STATUS.LOADING,
                underWritingErrorMessage: "",
                underWritingResult: null
            }
        case CLIENT.UNDERWRITING_SUCCESS:
            return {
                ...state,
                underWritingStatus: STATUS.SUCCESS,
                underWritingErrorMessage: "",
                underWritingResult: payload
            }
        case CLIENT.UNDERWRITING_FAILURE:
            return {
                ...state,
                underWritingStatus: STATUS.ERROR,
                underWritingErrorMessage: payload,
                underWritingResult: ""
            }
        case CLIENT.UNDERWRITING_RESET:
            return {
                ...state,
                underWritingStatus: STATUS.DEFAULT,
                underWritingErrorMessage: "",
                underWritingResult: null
            }

        case CLIENT.EDIT_UNDERWRITING_LOADING:
            return {
                ...state,
                editUnderWritingStatus: STATUS.LOADING,
                editUnderWritingErrorMessage: "",
                editUnderWritingResult: null
            }
        case CLIENT.EDIT_UNDERWRITING_SUCCESS:
            return {
                ...state,
                editUnderWritingStatus: STATUS.SUCCESS,
                editUnderWritingErrorMessage: "",
                editUnderWritingResult: payload
            }
        case CLIENT.EDIT_UNDERWRITING_FAILURE:
            return {
                ...state,
                editUnderWritingStatus: STATUS.ERROR,
                editUnderWritingErrorMessage: payload,
                editUnderWritingResult: ""
            }
        case CLIENT.EDIT_UNDERWRITING_RESET:
            return {
                ...state,
                editUnderWritingStatus: STATUS.DEFAULT,
                editUnderWritingErrorMessage: "",
                editUnderWritingResult: null
            }

        case CLIENT.LOAN_APPROVAL_LOADING:
            return {
                ...state,
                loanRequestStatus: STATUS.LOADING,
                loanRequestErrorMessage: "",
                loanRequestResult: null
            }
        case CLIENT.LOAN_APPROVAL_SUCCESS:
            return {
                ...state,
                loanRequestStatus: STATUS.SUCCESS,
                loanRequestErrorMessage: "",
                loanRequestResult: payload
            }
        case CLIENT.LOAN_APPROVAL_FAILURE:
            return {
                ...state,
                loanRequestStatus: STATUS.ERROR,
                loanRequestErrorMessage: payload,
                loanRequestResult: ""
            }
        case CLIENT.LOAN_APPROVAL_RESET:
            return {
                ...state,
                loanRequestStatus: STATUS.DEFAULT,
                loanRequestErrorMessage: "",
                loanRequestResult: null
            }

        case CLIENT.EDIT_LOAN_APPROVAL_LOADING:
            return {
                ...state,
                editLoanRequestStatus: STATUS.LOADING,
                editLoanRequestErrorMessage: "",
                editLoanRequestResult: null
            }
        case CLIENT.EDIT_LOAN_APPROVAL_SUCCESS:
            return {
                ...state,
                editLoanRequestStatus: STATUS.SUCCESS,
                editLoanRequestErrorMessage: "",
                editLoanRequestResult: payload
            }
        case CLIENT.EDIT_LOAN_APPROVAL_FAILURE:
            return {
                ...state,
                editLoanRequestStatus: STATUS.ERROR,
                editLoanRequestErrorMessage: payload,
                editLoanRequestResult: ""
            }
        case CLIENT.EDIT_LOAN_APPROVAL_RESET:
            return {
                ...state,
                editLoanRequestStatus: STATUS.DEFAULT,
                editLoanRequestErrorMessage: "",
                editLoanRequestErrorMessage: null
            }

        case CLIENT.LOAN_HISTORY_LOADING:
            return {
                ...state,
                loanHistoryRequestStatus: STATUS.LOADING,
                loanHistoryRequestErrorMessage: "",
                loanHistoryRequestResult: []
            }
        case CLIENT.LOAN_HISTORY_SUCCESS:
            return {
                ...state,
                loanHistoryRequestStatus: STATUS.SUCCESS,
                loanHistoryRequestErrorMessage: "",
                loanHistoryRequestResult: payload
            }
        case CLIENT.LOAN_HISTORY_FAILURE:
            return {
                ...state,
                loanHistoryRequestStatus: STATUS.ERROR,
                loanHistoryRequestErrorMessage: payload,
                loanHistoryRequestResult: []
            }
        case CLIENT.LOAN_HISTORY_RESET:
            return {
                ...state,
                loanHistoryRequestStatus: STATUS.DEFAULT,
                loanHistoryRequestErrorMessage: "",
                loanHistoryRequestResult: []
            }
        case CLIENT.PENDING_LOAN_DETAIL_LOADING:
            return {
                ...state,
                pendingLoanDetailStatus: STATUS.DEFAULT,
            }
        case CLIENT.PENDING_LOAN_DETAIL_SUCCESS:
            return {
                ...state,
                pendingLoanDetailStatus: STATUS.SUCCESS,
                pendingLoanDetailResult: payload,
                pendingLoanDetailErrorMessage: ""
            }
        case CLIENT.PENDING_LOAN_DETAIL_FAILURE:
            return {
                ...state,
                pendingLoanDetailStatus: STATUS.ERROR,
                pendingLoanDetailResult: null,
                pendingLoanDetailErrorMessage: payload
            }
        case CLIENT.PENDING_LOAN_DETAIL_RESET:
            return {
                ...state,
                pendingLoanDetailStatus: STATUS.DEFAULT,
                pendingLoanDetailResult: null,
                pendingLoanDetailErrorMessage: ""
            }
        case CLIENT.ACTIVE_LOAN_DETAIL_LOADING:
            return {
                ...state,
                activeLoanDetailStatus: STATUS.LOADING,
            }
        case CLIENT.ACTIVE_LOAN_DETAIL_SUCCESS:
            return {
                ...state,
                activeLoanDetailStatus: STATUS.SUCCESS,
                activeLoanDetailResult: payload,
                activeLoanDetailErrorMessage: ""
            }
        case CLIENT.ACTIVE_LOAN_DETAIL_FAILURE:
            return {
                ...state,
                activeLoanDetailStatus: STATUS.ERROR,
                activeLoanDetailResult: null,
                activeLoanDetailErrorMessage: payload
            }
        case CLIENT.ACTIVE_LOAN_DETAIL_RESET:
            return {
                ...state,
                activeLoanDetailStatus: STATUS.DEFAULT,
                activeLoanDetailResult: null,
                activeLoanDetailErrorMessage: ""
            }
        case CLIENT.INITIATE_PUSH_PAYMENT_LOADING:
            return {
                ...state,
                initiatePushPaymentStatus: STATUS.LOADING,
            }
        case CLIENT.INITIATE_PUSH_PAYMENT_SUCCESS:
            return {
                ...state,
                initiatePushPaymentStatus: STATUS.SUCCESS,
                initiatePushPaymentResult: payload,
                initiatePushPaymentErrorMessage: ""
            }
        case CLIENT.INITIATE_PUSH_PAYMENT_FAILURE:
            return {
                ...state,
                initiatePushPaymentStatus: STATUS.ERROR,
                initiatePushPaymentResult: null,
                initiatePushPaymentErrorMessage: payload
            }
        case CLIENT.INITIATE_PUSH_PAYMENT_RESET:
            return {
                ...state,
                initiatePushPaymentStatus: STATUS.DEFAULT,
                initiatePushPaymentResult: null,
                initiatePushPaymentErrorMessage: ""
            }

        case CLIENT.NIDA_VERIFICATION_LOADING:
            return {
                ...state,
                nidaStatus: STATUS.LOADING,
            }
        case CLIENT.NIDA_VERIFICATION_SUCCESS:
            return {
                ...state,
                nidaStatus: STATUS.SUCCESS,
                nidaResult: payload,
                nidaErrorMessage: ""
            }
        case CLIENT.NIDA_VERIFICATION_FAILURE:
            return {
                ...state,
                nidaStatus: STATUS.ERROR,
                nidaResult: null,
                nidaErrorMessage: payload
            }
        case CLIENT.NIDA_VERIFICATION_RESET:
            return {
                ...state,
                nidaStatus: STATUS.DEFAULT,
                nidaResult: null,
                nidaErrorMessage: ""
            }

        case CLIENT.REFERENCE_LOADING:
            return {
                ...state,
                referenceStatus: STATUS.LOADING,
            }
        case CLIENT.REFERENCE_SUCCESS:
            return {
                ...state,
                referenceStatus: STATUS.SUCCESS,
                referenceResult: payload,
                referenceErrorMessage: ""
            }
        case CLIENT.REFERENCE_FAILURE:
            return {
                ...state,
                referenceStatus: STATUS.ERROR,
                referenceResult: null,
                referenceErrorMessage: payload
            }
        case CLIENT.REFERENCE_RESET:
            return {
                ...state,
                referenceStatus: STATUS.DEFAULT,
                referenceResult: null,
                referenceErrorMessage: ""
            }
        default:
            return state;
    }
}