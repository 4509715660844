import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, FormControl, FormLabel, Input, Select, Stack, Typography, Option } from "@mui/joy";
import { toast } from 'react-toastify';
import { LoadingView, PageTitle } from "../../../components";
import { connect, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { ambassadorApplicationRequest, ambassadorApplicationReset } from "../../../store/actions";
import { STATUS } from "../../../constant";
import { useTranslation } from "react-i18next";

// locations
const locations = require('../../../assets/locations.json');


const AmbassadorApplicationPage = ({
    loginResult,
    ambassadorApplicationStatus,
    ambassadorApplicationResult,
    ambassadorApplicationErrorMessage
}) => {

    const navigate = useNavigate()
    const { state } = useLocation()
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const [fname, setFname] = useState('');
    const [sname, setSname] = useState('');
    const [lname, setLname] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [bussiness, setBussiness] = useState('');
    const [ambType, setAmbType] = useState('')

    const [regionList, setRegionList] = useState([]);
    const [region, setRegion] = useState('');
    const [districtList, setDistrictList] = useState([]);
    const [district, setDistrict] = useState('');

    useEffect(() => {
        if (ambassadorApplicationStatus == STATUS.SUCCESS) {
            toast(t("ambApplication.success"));
            setTimeout(() => {
                navigate(-1);
            }, 1000)
            dispatch(ambassadorApplicationReset())
        }
        else if (ambassadorApplicationStatus == STATUS.ERROR) {
            toast.error(ambassadorApplicationErrorMessage);
            dispatch(ambassadorApplicationReset());
        }
    })


    useEffect(() => {
        let _regions = [];
        locations.map(val => {
            _regions.push(val.name);
        });
        setRegionList(_regions);
    }, []);

    useEffect(() => {
        //first clear selected district
        setDistrict('')
        locations.find((location) => {
            //return location.name = region
            let _districtList = []
            if (location.name == region) {
                location.districts.map(val => {
                    _districtList.push(val.name);
                })
                setDistrictList(_districtList);
                return;
            }
        });
    }, [region])

    const handleRegion = (event, newValue) => {
        setRegion(newValue)
    }

    const handleDistrict = (event, newValue) => {
        setDistrict(newValue)
    }

    const handleAmbType = (event, newValue) => {
        setAmbType(newValue)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        let name = fname + " " + sname + " " + lname
        let names = fname && sname && lname;
        let location = region && district;
        let contacts = mobile || email
        if (names && location && contacts && ambType) {
            return dispatch(ambassadorApplicationRequest(loginResult.token, name, mobile, email, region, district, bussiness, ambType));
        } else {
            toast.error(t("ambApplication.error"))
        }
    }

    // function to check loading status and render loading view
    const checkLoading = () => {
        if (ambassadorApplicationStatus === STATUS.LOADING) {
            return true
        } else {
            return false
        }
    }

    return (
        <Box>
            <PageTitle title={t("ambApplication.title")} />
            {/* <ToastContainer /> */}

            <LoadingView loading={checkLoading()} />
            <Card variant="outlined" sx={{ backgroundColor: 'background.level1', justifyContent: 'center', alignItems: 'center' }}>
                <CardContent >
                    <Typography level="title-lg" sx={{ color: 'background.appcolor', textAlign: 'center' }}>{t("ambApplication.desc")}</Typography>


                    {/* form start */}
                    <Stack component='form' onSubmit={handleSubmit} gap={4}>
                        <Stack direction={{ xs: 'column', md: 'row' }} gap={2}>
                            {/* first name */}
                            <FormControl sx={{ flex: 1 }} required>
                                <FormLabel>{t("ambApplication.fname")}</FormLabel>
                                <Input type="text" value={fname} onChange={(e) => setFname(e.target.value)} placeholder={t("ambApplication.fnamePlaceholder")} />
                            </FormControl>

                            {/* second name */}
                            <FormControl sx={{ flex: 1 }} required>
                                <FormLabel>{t("ambApplication.sname")}</FormLabel>
                                <Input type="text" value={sname} onChange={(e) => setSname(e.target.value)} placeholder={t("ambApplication.snamePlaceholder")} />
                            </FormControl>

                            {/* last name */}
                            <FormControl sx={{ flex: 1 }} required>
                                <FormLabel>{t("ambApplication.lname")}</FormLabel>
                                <Input type="text" value={lname} onChange={(e) => setLname(e.target.value)} placeholder={t("ambApplication.lnamePlaceholder")} />
                            </FormControl>
                        </Stack>

                        <Stack direction={{ xs: 'column', md: 'row' }} gap={2}>
                            {/* mobile number */}
                            <FormControl sx={{ flex: 1 }} required>
                                <FormLabel>{t("ambApplication.mobile")}</FormLabel>
                                <Input type="tel" value={mobile} onChange={(e) => setMobile(e.target.value)} placeholder={t("ambApplication.mobilePlaceholder")} />
                            </FormControl>

                            {/* email */}
                            <FormControl sx={{ flex: 1 }}>
                                <FormLabel>{t("ambApplication.email")}</FormLabel>
                                <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder={t("ambApplication.emailPlaceholder")} />
                            </FormControl>
                        </Stack>

                        <Stack direction={{ xs: 'column', md: 'row' }} gap={2}>
                            {/* region */}
                            <FormControl sx={{ flex: 1 }} required>
                                <FormLabel>{t("ambApplication.region")}</FormLabel>
                                <Select placeholder={t("ambApplication.regionPlaceholder")} onChange={handleRegion} required>
                                    {regionList.map((dt) => (
                                        <Option value={dt}>{dt}</Option>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* district */}
                            {region && <FormControl sx={{ flex: 1 }} required>
                                <FormLabel>{t("ambApplication.district")}</FormLabel>
                                <Select placeholder={t("ambApplication.districtPlaceholder")} onChange={handleDistrict} required>
                                    {districtList.map((dt) => (
                                        <Option value={dt}>{dt}</Option>
                                    ))}
                                </Select>
                            </FormControl>}
                        </Stack>

                        <Stack direction={{ xs: 'column', md: 'row' }} gap={2}>
                            {/* business activity*/}
                            <FormControl sx={{ flex: 1 }}>
                                <FormLabel>{t("ambApplication.business")}</FormLabel>
                                <Input type="text" value={bussiness} onChange={(e) => setBussiness(e.target.value)} placeholder={t("ambApplication.businessPlaceholder")} />
                            </FormControl>

                            {/* confirm password */}
                            <FormControl sx={{ flex: 1 }} required>
                                <FormLabel>{t("ambApplication.type.title")}</FormLabel>
                                <Select placeholder={t("ambApplication.type.placeholder")} onChange={handleAmbType} required>
                                    <Option value='Individual'>{t("ambApplication.type.individual")}</Option>
                                    <Option value='Group'>{t("ambApplication.type.group")}</Option>
                                    <Option value='Company'>{t("ambApplication.type.company")}</Option>
                                </Select>
                            </FormControl>
                        </Stack>

                        <Stack gap={4} sx={{ mt: 2 }}>
                            <Button type="submit" fullWidth>
                                {t("ambApplication.button")}
                            </Button>
                        </Stack>
                    </Stack>

                </CardContent>
            </Card>
        </Box>
    )
}

const mapPropsToState = ({ authClient, account }) => {
    const {
        otpResult: loginResult
    } = authClient
    const {
        ambassadorApplicationStatus: ambassadorApplicationStatus,
        ambassadorApplicationResult: ambassadorApplicationResult,
        ambassadorApplicationErrorMessage: ambassadorApplicationErrorMessage
    } = account

    return {
        loginResult,
        ambassadorApplicationStatus,
        ambassadorApplicationResult,
        ambassadorApplicationErrorMessage
    }
}

export default connect(mapPropsToState, {})(AmbassadorApplicationPage)