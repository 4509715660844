import * as React from 'react';
import { useDispatch, connect } from 'react-redux';

import { useColorScheme } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Avatar from '@mui/joy/Avatar';
import Tooltip from '@mui/joy/Tooltip';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import ListDivider from '@mui/joy/ListDivider';

import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import image from '../../constant/image';

import { NAVIGATE_TO_AMBASSADORAPPLICATIONPAGE, NAVIGATE_TO_CLIENTLOANHISTORYPAGE, NAVIGATE_TO_CLIENTPROFILEPAGE, NAVIGATE_TO_CLIENTSUPPORTPAGE, NAVIGATE_TO_CLIENTTRANSACTIONHISTORYPAGE, NAVIGATE_TO_HOMECLIENTPAGE } from '../../route/types';

import { CircularProgress, Divider, Link, Switch } from '@mui/joy';
import { useNavigate, useLocation } from 'react-router-dom';
import { resetUserType, clientLogoutRequest, clientNotificationRequest, clientNotificationReset, clientDetailsReset } from '../../store/actions'
import { FILE_BASE, STATUS, } from '../../constant';
import { DarkMode, EditNoteRounded, LightMode, Notifications, Settings, SupervisedUserCircle } from '@mui/icons-material';
import LanguageMenu from '../molecules/LanguageMenu';
import { useTranslation } from 'react-i18next';

function ColorSchemeToggle() {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);
  const { t } = useTranslation();
  React.useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return <IconButton size="sm" variant="outlined" color="primary" />;
  }
  return (
    <Switch
      checked={mode == "dark"}
      id="toogle-mode"
      startDecorator={mode === "light" ? t("header.switchDark") : t("header.switchLight")}
      onChange={() => {
        if (mode === 'light') {
          setMode('dark');
        } else {
          setMode('light');
        }
      }}
      size="lg"
      slotProps={{
        input: { 'aria-label': 'Dark mode' },
        thumb: {
          children: mode === "light" ? <LightMode /> : <DarkMode />,
        },
      }
      }
      sx={{ '--Switch-thumbSize': '16px', width: '100%', pl: 1.5 }}
    />
  );
}

const HeaderClient = ({
  loginResult,

  notificationStatus,
  notificationResult,
  notificationErrorMessage,

  clientDetailsStatus,
  clientDetailsResult
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { t } = useTranslation();

  const [refresh, setRefresh] = React.useState(false)

  const [name, setName] = React.useState('');
  const [mobile, setMobile] = React.useState('');
  const [profile, setProfile] = React.useState('');

  const [notList, setNotList] = React.useState([]);
  const [notLoad, setNotLoad] = React.useState(false);
  const [notError, setNotError] = React.useState("njgjjg");


  const logOut = () => {
    dispatch(clientLogoutRequest());
    dispatch(resetUserType());
  }

  React.useEffect(() => {


    if (clientDetailsStatus === STATUS.SUCCESS) {
      // console.log("CLIENT DATA: ", clientDetailsResult)
      setName(clientDetailsResult.message.name);
      setMobile(clientDetailsResult.message.mobile_number);
      setProfile(FILE_BASE + "/" + clientDetailsResult.message.profile_picture);
    } else if (clientDetailsStatus === STATUS.ERROR) {
      if (loginResult.name || loginResult.mobile || loginResult.profile_photo_url) {
        setName(loginResult.name)
        setMobile(loginResult.mobile)
        setProfile(FILE_BASE + "/" + loginResult.profile_photo_url)
      }
      dispatch(clientDetailsReset())
    }
  }, [clientDetailsStatus])

  React.useEffect(() => {
    if (notificationStatus === STATUS.SUCCESS) {
      setNotLoad(false);
      setNotError("");
      setNotList(notificationResult);
      dispatch(clientNotificationReset());
    }
    else if (notificationStatus === STATUS.ERROR) {
      setNotLoad(false);
      setNotList([])
      setNotError(notificationErrorMessage);
      dispatch(clientNotificationReset());
    }
  }, [notificationStatus])

  const requestNotification = () => {
    setNotLoad(true);
    dispatch(clientNotificationRequest(loginResult.token))
  }

  const HeaderNav = ({ title, route }) => {

    return (
      <Box sx={{ margin: 'auto' }}>
        <Link
          href={route}
          underline='none'
          sx={{
            color: location.pathname == route ? 'background.appcolor' : 'text.secondary',
            fontWeight: location.pathname == route ? '600' : 'normal'
          }}>
          {title}
        </Link>
        <Box sx={{
          display: location.pathname == route ? 'flex' : 'none',
          width: '80%',
          borderRadius: '50px',
          height: '3px',
          backgroundColor: 'background.appcolor',
          margin: 'auto'
        }} />
      </Box>
    )

  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
      }}
    >
      {/* Logo and App name */}
      <Link sx={{ display: 'flex', gap: 1, alignItems: 'center' }} underline='none' href={NAVIGATE_TO_HOMECLIENTPAGE}>
        <IconButton variant="soft" sx={{ backgroundColor: 'whitesmoke' }} size="sm">
          <Avatar
            src={image.Images.icon}
            sx={{ maxWidth: '32px', maxHeight: '32px', backgroundColor: 'transparent' }}
          />
        </IconButton>
        <Typography level="title-lg" sx={{
          // display: { xs: 'none', md: 'flex' },
          fontSize: '20px',
          color: 'background.appcolor'
        }}
        >SAVA</Typography>
      </Link>

      {/* link navigation */}
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ display: { xs: 'none', sm: 'flex' } }}
      >
        <HeaderNav route={NAVIGATE_TO_HOMECLIENTPAGE} title={t("header.home")} />
        <HeaderNav route={NAVIGATE_TO_CLIENTLOANHISTORYPAGE} title={t("header.loan")} />
        <HeaderNav route={NAVIGATE_TO_CLIENTTRANSACTIONHISTORYPAGE} title={t("header.transaction")} />
      </Stack>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1.5,
          alignItems: 'center',
        }}
      >
        {/* change language */}
        <LanguageMenu change={() => setRefresh(!refresh)} />

        {/* notifications */}
        <Dropdown>
          <MenuButton
            variant="plain"
            size="sm"
            sx={{ maxWidth: '32px', maxHeight: '32px', borderRadius: '9999999px' }}>
            <IconButton
              id="toggle-mode"
              size="sm"
              variant="plain"
              color="success"
              sx={{ alignSelf: 'center' }}
              onClick={requestNotification}
            >
              <Notifications />
            </IconButton>
          </MenuButton>
          <Menu
            placement="bottom"
            size="sm"
            sx={{
              zIndex: '99999',
              width: { xs: "90%", sm: "60%", md: '40%' },
              justifyContent: 'center',
              alignItems: 'center',
              p: 1,
              gap: 1,
              '--ListItem-radius': 'var(--joy-radius-sm)',
            }}>

            {/* notification header */}
            <Box sx={{
              display: 'flex',
              gap: 1,
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <Notifications />
              <Typography level='title-md'> {t("header.notification")}</Typography>
            </Box>
            <ListDivider />

            {notLoad ?
              <CircularProgress size='lg' thickness={3} sx={{ alignSelf: 'center' }} />
              : (
                notList.length > 0 ? notList.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignSelf: 'flex-start',
                      p: 0.7,
                      borderLeft: 2.5,
                      borderBottom: 0.6,
                      borderColor: 'green',
                      gap: 0.5
                    }}>
                    <Typography level='title-sm'>{item.title}</Typography>
                    <Typography level='body-sm'>{item.message}</Typography>
                    {/* <Divider /> */}
                  </Box>
                )) :
                  <Typography level='title-md' sx={{ p: 1, backgroundColor: 'lightgray', borderRadius: 'md' }}>{notError}</Typography>
              )}
          </Menu>
        </Dropdown>

        {/* profile menu */}
        <Dropdown>
          <MenuButton
            variant="plain"
            size="sm"
            sx={{ maxWidth: '32px', maxHeight: '32px', borderRadius: '9999999px' }}
          >
            <Avatar
              src={profile}
              sx={{ maxWidth: '32px', maxHeight: '32px' }}
            // alt='j'
            />
          </MenuButton>
          <Menu
            placement="bottom-end"
            size="sm"
            sx={{
              zIndex: '99999',
              p: 1,
              gap: 1,
              '--ListItem-radius': 'var(--joy-radius-sm)',
            }}
          >
            <MenuItem
              component="a"
              href={NAVIGATE_TO_CLIENTPROFILEPAGE}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Avatar
                  src={profile}
                  sx={{ borderRadius: '50%' }}
                />
                <Box sx={{ ml: 1.5 }}>
                  <Typography level="title-sm" textColor="text.primary">
                    {name.toLowerCase() // convert uppercase name to firstuppercase
                      .split(' ')
                      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(' ')}
                  </Typography>
                  <Typography level="body-xs" textColor="text.tertiary">
                    +{mobile}
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
            <ListDivider />

            {/* change theme */}
            <ColorSchemeToggle />

            {/* profile and settings */}
            <MenuItem
              component="a"
              href={NAVIGATE_TO_CLIENTPROFILEPAGE}>
              <Settings />
              {t("header.setting")}
            </MenuItem>

            {/* support */}
            <MenuItem
              component="a"
              href={NAVIGATE_TO_CLIENTSUPPORTPAGE}>
              <HelpRoundedIcon />
              {t("header.support")}
            </MenuItem>

            {/* ambassador application */}
            <MenuItem
              component="a"
              href={NAVIGATE_TO_AMBASSADORAPPLICATIONPAGE}>
              <EditNoteRounded />
              {t("header.ambassadorApplication")}
            </MenuItem>

            {/* log out */}
            <ListDivider />
            <MenuItem
              component='a'
              href='#' onClick={logOut}>
              <LogoutRoundedIcon />
              {t("header.logout")}
            </MenuItem>
          </Menu>
        </Dropdown>
      </Box>
    </Box>
  );
}

const mapStateToProps = ({ authClient, account }) => {
  const {
    otpResult: loginResult
  } = authClient
  const {
    clientNotificationStatus: notificationStatus,
    clientNotificationResult: notificationResult,
    clientNotificationErrorMessage: notificationErrorMessage,

    clientDetailsStatus: clientDetailsStatus,
    clientDetailsResult: clientDetailsResult,
    clientDetailsErrorMessage: clientDetailsErrorMessage,
  } = account


  return {
    loginResult,

    notificationStatus,
    notificationResult,
    notificationErrorMessage,

    clientDetailsStatus,
    clientDetailsResult
  }
}

export default connect(mapStateToProps, {})(HeaderClient)