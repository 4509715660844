import { ACTION_ACCOUNT_REMOVE, ACTION_RESET_APP_STATE, CLIENT,  } from "../../constant";

export function removeClientAccountRequest() {
    // clearUserData();
    return {
        type: ACTION_ACCOUNT_REMOVE
    }
}

export function uploadProfilePhoto(token, file) {
    return {
        type: CLIENT.UPDATE_PROFILE_PHOTO_REQUEST,
        payload: {
            token,
            file
        }
    }
}

export function uploadProfilePhotoReset() {
    return {
        type: CLIENT.UPDATE_PROFILE_PHOTO_RESET
    }
}

export function uploadSignatureRequest(token, file){
    return {
        type: CLIENT.UPLOAD_SIGNATURE_REQUEST,
        payload: {
            token,
            file
        }
    }
}

export function uploadSignatureReset(){
    return {
        type: CLIENT.UPLOAD_SIGNATURE_RESET
    }
}

export function fetchSupportContent(token, language, category="help") {
    return {
        type: CLIENT.SUPPORT_LIST_REQUEST,
        payload: {
            token,
            language,
            category
        }
    }
}

export function fetchSupportContentReset() {
    return {
        type: CLIENT.SUPPORT_LIST_RESET,
    }
}

export function fetchPaymentMethodRequest(token, language, category="payment") {
    return {
        type: CLIENT.PAYMENT_METHOD_LIST_REQUEST,
        payload: {
            token,
            language,
            category
        }
    }
}

export function paymentMethodReset() {
    return {
        type: CLIENT.PAYMENT_METHOD_LIST_RESET
    }
}

export function transactionHistoryRequest(token) {
    return {
        type: CLIENT.TRANSACTION_HISTORIES_REQUEST,
        payload: {
            token
        }
    }
}

export function transactionHistoryReset() {
    return {
        type: CLIENT.TRANSACTION_HISTORIES_RESET
    }
}

export function sendClientChatRequest(token, message) {
    return {
        type: CLIENT.SEND_CLIENT_CHAT_REQUEST,
        payload: {
            token,
            message
        }
    }
}

export function sendClientChatReset() {
    return {
        type: CLIENT.SEND_CLIENT_CHAT_RESET
    }
}

export function clientChatHistoryRequest(token) {
    return {
        type: CLIENT.CLIENT_CHAT_LIST_REQUEST,
        payload: {
            token
        }
    }
}

export function clientChatHistoryReset() {
    return {
        type: CLIENT.CLIENT_CHAT_LIST_RESET,
    }
}

export function clientNotificationRequest(token) {
    return {
        type: CLIENT.CLIENT_NOTIFICATION_REQUEST,
        payload: {
            token
        }
    }
}

export function clientNotificationReset() {
    return {
        type: CLIENT.CLIENT_NOTIFICATION_RESET
    }
}

export function resetClientAppStateOnLoading() {
    return {
        type: ACTION_RESET_APP_STATE
    }
}

export function clientDetailsRequest(token) {
    return {
        type: CLIENT.CLIENT_DETAILS_REQUEST,
        payload: {
            token
        }
    }
}

export function clientDetailsReset() {
    return {
        type: CLIENT.CLIENT_DETAILS_RESET
    }
}

export function ambassadorApplicationRequest(token, name, mobile, email, region, district, bussiness, ambType) {
    return {
        type: CLIENT.AMBASSADOR_APPLICATION_REQUEST,
        payload: {
            token,
            name,
            mobile,
            email,
            region,
            district,
            bussiness,
            ambType
        }
    }
}

export function ambassadorApplicationReset() {
    return {
        type: CLIENT.AMBASSADOR_APPLICATION_RESET,
    }
}