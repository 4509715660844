import { CLIENT } from "../../constant"


export function ambassadorValidateReset() {
    return {
        type: CLIENT.AMBASSADOR_VALIDATE_RESET
    }
}

export function ambassadorValidateRequest(token, tile, loan_amount) {
    return {
        type: CLIENT.AMBASSADOR_VALIDATE_REQUEST,
        payload: {
            token,
            tile,
            loan_amount
        }
    }
}

export function underwritingRequest(token, tile, amount, duration, pay_plan, source_of_income, monthly_income, loan_use,
    collateral, guarantor_name, guarantor_phone, guarantor_relationship) {
    return {
        type: CLIENT.UNDERWRITING_REQUEST,
        payload: {
            token,
            tile,
            amount,
            duration,
            pay_plan,
            source_of_income,
            monthly_income,
            loan_use,
            collateral,
            guarantor_name,
            guarantor_phone,
            guarantor_relationship,
        }
    }
}

export function underwritingReset() {
    return {
        type: CLIENT.UNDERWRITING_RESET
    }
}

export function loanApprovalRequest(token, tile, amount, duration, pay_plan) {
    return {
        type: CLIENT.LOAN_APPROVAL_REQUEST,
        payload: {
            token,
            tile,
            amount,
            duration,
            pay_plan
        }
    }
}

export function loanApprovalReset() {
    return {
        type: CLIENT.LOAN_APPROVAL_RESET
    }
}

export function editUnderwritingRequest(token,loan_id, tile, amount, duration, pay_plan, source_of_income, monthly_income, loan_use,
    collateral, guarantor_name, guarantor_phone, guarantor_relationship) {
    return {
        type: CLIENT.EDIT_UNDERWRITING_REQUEST,
        payload: {
            token,
            loan_id,
            tile,
            amount,
            duration,
            pay_plan,
            source_of_income,
            monthly_income,
            loan_use,
            collateral,
            guarantor_name,
            guarantor_phone,
            guarantor_relationship,
        }
    }
}

export function editUnderwritingReset() {
    return {
        type: CLIENT.EDIT_UNDERWRITING_RESET
    }
}

export function editLoanApprovalRequest(token, loan_id, tile, amount, duration, pay_plan) {
    return {
        type: CLIENT.EDIT_LOAN_APPROVAL_REQUEST,
        payload: {
            token,
            loan_id,
            tile,
            amount,
            duration,
            pay_plan
        }
    }
}

export function editLoanApprovalReset() {
    return {
        type: CLIENT.EDIT_LOAN_APPROVAL_RESET
    }
}

export function loanHistoryRequest(token) {
    return {
        type: CLIENT.LOAN_HISTORY_REQUEST,
        payload: {
            token
        }
    }
}

export function loanHistoryReset() {
    return {
        type: CLIENT.LOAN_HISTORY_RESET
    }
}

export function pendingLoanDetailRequest(token) {
    return {
        type: CLIENT.PENDING_LOAN_DETAIL_REQUEST,
        payload: {
            token
        }
    }
}

export function pendingLoanDetailReset() {
    return {
        type: CLIENT.PENDING_LOAN_DETAIL_RESET
    }
}

export function activeLoanDetailRequest(token) {
    return {
        type: CLIENT.ACTIVE_LOAN_DETAIL_REQUEST,
        payload: {
            token
        }
    }
}

export function activeLoanDetailReset() {
    return {
        type: CLIENT.ACTIVE_LOAN_DETAIL_RESET
    }
}

export function nidaVerificationRequest(token, data) {
    return {
        type: CLIENT.NIDA_VERIFICATION_REQUEST,
        payload: {
            token,
            data
        }
    }
}

export function nidaVerificationReset() {
    return {
        type: CLIENT.NIDA_VERIFICATION_RESET
    }
}

export function referenceRequest(token, data){
    return {
        type: CLIENT.REFERENCE_REQUEST,
        payload: {
            token,
            data
        }
    }
}

export function referenceReset(){
    return {
        type: CLIENT.REFERENCE_RESET
    }
}

export function initiatePushPaymentRequest(token, amount) {
    return {
        type: CLIENT.INITIATE_PUSH_PAYMENT_REQUEST,
        payload: {
            token,
            amount
        }
    }
}

export function initiatePushPaymentReset() {
    return {
        type: CLIENT.INITIATE_PUSH_PAYMENT_RESET
    }
}